/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import {
	Box,
	Typography,
	Grid,
	Dialog,
	DialogActions,
	DialogContent,
	MenuItem,
	DialogTitle,
} from "@material-ui/core";
import Loader from "~/components/Loader";
import Card from "~/components/Card";
import Table from "~/components/Table";
import DateCountdown from "~/components/DateCountdown";
import TextField from "~/components/Forms/TextField";
import Button from "~/components/Forms/Button";
// import ButtonGroup from "~/components/Forms/ButtonGroup";

import {
	fetchBorletteTransactionSummary,
	updateBorlettePayment,
} from "~/redux/helpers/transaction";

import useStyles from "./styles";

export default ({
	onPreview,
	onPublish,
	borletteDetails,
	countDown,
	pending,
	previewProgress,
	publishProgress,
}) => {
	const classes = useStyles();
	const { borletteId } = useParams();
	const [pendingSalesSummary, setPendingSalesSummary] = useState(false);
	const [agentId, setAgentId] = useState(null);

	const [borletteCountDown, setBorletteCountDown] = useState(countDown);
	const [isMakePaymentDialogOpen, setIsMakePaymentDialogOpen] = useState(
		false
	);
	const [selectedPaymentAmount, setSelectedPaymentAmount] = useState(null);
	const [
		selectedPaymentTransactionType,
		setSelectedPaymentTransactionType,
	] = useState("PAYMENT_RECEIVED");
	const [results, setResults] = useState([]);

	const [transactions, setTransactions] = useState([]);
	const [salesSummary, setSalesSummary] = useState({
		totalSales: 0,
		totalCommissions: 0,
		totalMegapayCommissions: 0,
		totalRewards: 0,
		totalAmountPaid: 0,
		totalAmountReceived: 0,
		totalPendingBalanceAgent: 0,
		totalPendingBalanceMegapay: 0,
		totalProfit: 0,
	});
	const columns = [
		{ id: "agent", label: "Agent" },
		{ id: "sales", label: "Sales" },
		{ id: "commissions", label: "Agent Comm." },
		{ id: "megapayCommissions", label: "Megapay Comm." },
		{ id: "rewards", label: "Rewards" },
		// { id: "paymentMadeToMegapay", label: "Payment To Megapay" },
		{ id: "paymentReceivedFromAgent", label: "Payment From Agent" },
		{ id: "amountToPay", label: "Amount to Pay" },
		// { id: "pendingBalanceAgent", label: "Pending Agent Balance" },
		{ id: "action", label: "Action" },
	];

	const getBorletteSalesSummary = () => {
		setPendingSalesSummary(true);
		fetchBorletteTransactionSummary(borletteId)
			.then((response) => {
				const transactions = [];
				const salesSummary = {
					totalSales: 0,
					totalCommissions: 0,
					totalMegapayCommissions: 0,
					totalRewards: 0,
					totalAmountPaid: 0,
					totalAmountReceived: 0,
					totalPendingBalanceAgent: 0,
					totalPendingBalanceMegapay: 0,
					totalProfit: 0,
				};
				for (let agentId in response.transactionSummary) {
					const {
						agent,
						commissions,
						megapayCommissions,
						paymentMadeToAgent,
						paymentMadeToMegapay,
						paymentReceivedFromAgent,
						rewards,
						sales,
					} = response.transactionSummary[agentId];
					const transactionItem = {
						agent,
						pending: false,
						agentId: parseInt(agentId),
						commissions: commissions / 100,
						megapayCommissions: megapayCommissions / 100,
						paymentMadeToAgent: paymentMadeToAgent / 100,
						paymentMadeToMegapay: paymentMadeToMegapay / 100,
						paymentReceivedFromAgent:
							paymentReceivedFromAgent / 100,
						rewards: rewards / 100,
						sales: sales / 100,
						amountToPay: (commissions + rewards - paymentMadeToAgent) / 100,
						profit:
							(sales -
								commissions -
								megapayCommissions -
								rewards) /
							100,
						pendingBalanceAgent:
							(sales -
								commissions -
								rewards -
								paymentReceivedFromAgent +
								paymentMadeToAgent) /
							100,
						pendingBalanceMegapay:
							paymentMadeToMegapay - megapayCommissions / 100,
					};
					salesSummary.totalSales += transactionItem.sales;
					salesSummary.totalCommissions +=
						transactionItem.commissions;
					salesSummary.totalMegapayCommissions +=
						transactionItem.megapayCommissions;
					salesSummary.totalRewards += transactionItem.rewards;
					salesSummary.totalAmountPaid += transactionItem.paymentMadeToAgent;
					salesSummary.totalAmountReceived +=
						transactionItem.paymentReceivedFromAgent;
					salesSummary.totalPendingBalanceAgent += transactionItem.pendingBalanceAgent;
					salesSummary.totalPendingBalanceMegapay +=
						transactionItem.pendingBalanceMegapay;
					salesSummary.totalProfit += transactionItem.profit;
					transactions.push(transactionItem);
				}
				setTransactions(transactions);
				setSalesSummary(salesSummary);
			})
			.catch((error) => console.log(error))
			.finally(() => setPendingSalesSummary(false));
	};

	const updatePayment = () => {
		setIsMakePaymentDialogOpen(false);
		setTransactions(
			transactions.map((item) =>
				item.agentId === agentId
					? {
							...item,
							pending: true,
					  }
					: item
			)
		);
		updateBorlettePayment(
			borletteId,
			agentId,
			selectedPaymentTransactionType,
			selectedPaymentAmount * 100
		)
			.then((response) => console.log(response))
			.catch((error) => console.log(error))
			.finally(() => {
				setTransactions(
					transactions.map((item) =>
						item.agentId === agentId
							? {
									...item,
									pending: false,
							  }
							: item
					)
				);
				getBorletteSalesSummary();
				closePaymentDialog();
			});
	};

	const handlePreview = () => {
		onPreview(results);
	};

	const handlePublish = () => {
		onPublish(results);
	};

	const renderAction = ({
		agentId,
		megapayCommissions,
		pendingBalanceAgent,
		pending,
	}) => {
		return Math.abs(pendingBalanceAgent) !== 0 ? (
			<Button
				pending={pending}
				onClick={() => {
					setAgentId(agentId);
					setIsMakePaymentDialogOpen(true);
				}}
				color="primary"
				variant="contained"
			>
				Update Payment
			</Button>
		) : null;
	};

	const closePaymentDialog = () => {
		setAgentId(agentId);
		setIsMakePaymentDialogOpen(false);
	}

	useEffect(() => {
		getBorletteSalesSummary();
	}, []);

	useEffect(() => {
		setBorletteCountDown(countDown === 0 ? -1 : countDown);
	}, [countDown]);

	const rows = transactions.map((item) => ({
		...item,
		action: renderAction(item),
	}));

	if (pending) {
		return <Loader />;
	}
	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12} md={4}>
					<Card title="Lottery Information" fontIcon="fa-info-circle">
						<Box className={classes.container}>
							<Grid container spacing={3}>
								<Grid item xs={6}>
									<Typography variant="h4">ID:</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="h5">
										{borletteId}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={6}>
									<Typography variant="h4">Type:</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="h5">
										Borlette
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={6}>
									<Typography variant="h4">Name:</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="h5">
										{borletteDetails.name}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={6}>
									<Typography variant="h4">
										Scheduled On:
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="h5">
										{moment(
											borletteDetails.schedule
										).format("DD MMM, YYYY hh:mm A")}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={6}>
									<Typography variant="h4">
										Created On:
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="h5">
										{moment(
											borletteDetails.createdAt
										).format("DD MMM, YYYY hh:mm A")}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={6}>
									<Typography variant="h4">
										Status:
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="h5">
										{borletteDetails.status}
									</Typography>
								</Grid>
							</Grid>
						</Box>
					</Card>
				</Grid>
				<Grid item xs={12} md={4}>
					<Card
						title="Lottery Results"
						fontIcon="fa-money"
						className={classes.fullHeight}
					>
						<Box className={classes.resultContainer}>
							{borletteDetails.status === "SCHEDULED" ? (
								borletteCountDown >= 0 ? (
									<Grid
										container
										justify="center"
										spacing={3}
									>
										<Grid item>
											<DateCountdown
												mostSignificantFigure="day"
												countDown={borletteCountDown}
												callback={() =>
													setBorletteCountDown(-1)
												}
											/>
										</Grid>
									</Grid>
								) : (
									<>
										<Grid container spacing={3}>
											<Grid item xs={12} sm={4}>
												<TextField
													type="text"
													label="First"
													value={results[0]}
													onChange={(e) =>
														setResults([
															e.target.value,
															results[1],
															results[2],
														])
													}
												/>
											</Grid>
											<Grid item xs={12} sm={4}>
												<TextField
													type="text"
													label="Second"
													value={results[1]}
													onChange={(e) =>
														setResults([
															results[0],
															e.target.value,
															results[2],
														])
													}
												/>
											</Grid>
											<Grid item xs={12} sm={4}>
												<TextField
													type="text"
													label="Third"
													value={results[2]}
													onChange={(e) =>
														setResults([
															results[0],
															results[1],
															e.target.value,
														])
													}
												/>
											</Grid>
										</Grid>
										<Box py={2}>
											<Grid
												container
												spacing={2}
												justify="flex-end"
											>
												<Grid item xs={12} sm={6}>
													<Button
														pending={
															previewProgress
														}
														disabled={
															previewProgress ||
															publishProgress
														}
														onClick={handlePreview}
														color="primary"
														variant="outlined"
														fullWidth
													>
														Preview
													</Button>
												</Grid>
												<Grid item xs={12} sm={6}>
													<Button
														pending={
															publishProgress
														}
														disabled={
															previewProgress ||
															publishProgress
														}
														onClick={handlePublish}
														color="primary"
														variant="contained"
														fullWidth
													>
														Publish
													</Button>
												</Grid>
											</Grid>
										</Box>
									</>
								)
							) : (
								<Grid
									container
									spacing={3}
									justify="center"
									alignItems="center"
								>
									{borletteDetails.results.map(
										(result, index) => (
											<Grid
												key={`result-item-${index}`}
												item
												xs={4}
												className={
													classes.resultBallContainer
												}
											>
												<Box
													className={
														classes.resultBall
													}
												>
													<Typography variant="h4">
														{result}
													</Typography>
												</Box>
											</Grid>
										)
									)}
								</Grid>
							)}
						</Box>
					</Card>
				</Grid>

				<Grid item xs={12} md={4}>
					<Card
						title="Amount Details"
						fontIcon="fa-money"
						className={classes.fullHeight}
					>
						<Box className={classes.container}>
							<Grid container spacing={3}>
								<Grid item xs={8}>
									<Typography variant="h4">
										Total Sales:
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography variant="h5">
										{salesSummary.totalSales}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={8}>
									<Typography variant="h4">
										Agent Commission:
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography variant="h5">
										{salesSummary.totalCommissions}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={8}>
									<Typography variant="h4">
										Megapay Commission:
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography variant="h5">
										{salesSummary.totalMegapayCommissions}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={8}>
									<Typography variant="h4">
										Total Rewards:
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography variant="h5">
										{salesSummary.totalRewards}
									</Typography>
								</Grid>
							</Grid>
							{/* <Grid container spacing={3}>
								<Grid item xs={6}>
									<Typography variant="h4">
										Total Amount Paid:
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="h5">
										{salesSummary.totalAmountPaid}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={6}>
									<Typography variant="h4">
										Total Amount Received:
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="h5">
										{salesSummary.totalAmountReceived}
									</Typography>
								</Grid>
							</Grid> */}
							{/* <Grid
								container
								spacing={2}
								className={classes.borderTop}
							>
								<Grid item xs={8}>
									<Typography variant="h4">
										Pending Agent Balance:
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography variant="h5">
										{salesSummary.totalCommissions +
											salesSummary.totalRewards -
											salesSummary.totalAmountPaid}
									</Typography>
								</Grid>
							</Grid> */}
							<Grid container spacing={3}>
								<Grid item xs={8}>
									<Typography variant="h4">
										Pending Megapay Balance:
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography variant="h5">
										{
											salesSummary.totalPendingBalanceMegapay
										}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item xs={8}>
									<Typography variant="h4">
										Amount Received from Agent:
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography variant="h5">
										{salesSummary.totalAmountReceived}
									</Typography>
								</Grid>
							</Grid>
							<Grid
								container
								spacing={3}
								className={classes.borderTop}
							>
								<Grid item xs={8}>
									<Typography variant="h4">
										Net Profit:
									</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography variant="h5">
										{salesSummary.totalProfit}
									</Typography>
								</Grid>
							</Grid>
						</Box>{" "}
					</Card>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Box className={classes.sectionHeader}>
						<Typography variant="h1">Sales Summary</Typography>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Table
						columns={columns}
						rows={rows}
						pending={pendingSalesSummary}
					></Table>
				</Grid>
			</Grid>
			<Dialog
				open={isMakePaymentDialogOpen}
				onClose={closePaymentDialog}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					Update Payment Details
				</DialogTitle>
				<DialogContent className={classes.dialog}>
					<TextField
						select
						type="select"
						label="Payment Type"
						value={selectedPaymentTransactionType}
						onChange={(e) =>
							setSelectedPaymentTransactionType(e.target.value)
						}
					>
						<MenuItem value={null}></MenuItem>
						<MenuItem value={"PAYMENT_RECEIVED"}>
							Amount Received From Agent
						</MenuItem>
						<MenuItem value={"PAYMENT_MADE"}>
							Amount Paid To Agent
						</MenuItem>
					</TextField>
					<br />
					<TextField
						autoFocus
						margin="dense"
						label="Enter Amount"
						onChange={(e) =>
							setSelectedPaymentAmount(e.target.value)
						}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={closePaymentDialog}
						color="primary"
						variant="outlined"
					>
						Cancel
					</Button>
					<Button
						onClick={updatePayment}
						color="primary"
						variant="contained"
					>
						Make Payment
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
