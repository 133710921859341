import axios from "axios";
import config from "~/config";
import { getAccessToken } from "~/redux/helpers";

export const fetchAgentList = async ({
	limit,
	offset,
	sortBy,
	sortOrder,
	searchTerm,
	status,
}) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/agent`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
			params: {
				limit,
				offset,
				sortBy,
				sortOrder,
				searchTerm,
				status,
			},
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};

export const fetchAgent = async (agentId) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/agent/${agentId}`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};

export const addAgent = async (agent) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/agent`,
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
			data: JSON.stringify(agent),
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};

export const updateAgent = async (agentId, agent) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/agent/${agentId}`,
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
			data: JSON.stringify(agent),
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};

export const updateAgentConfiguration = async (agentId, configuration) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/agent/config/${agentId}`,
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
			data: JSON.stringify(configuration),
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};

export const blockAgent = async (agentId) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/agent/block/${agentId}`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			}
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};

export const unBlockAgent = async (agentId) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/agent/unblock/${agentId}`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};