import axios from "axios";
import config from "~/config";

export const fetchClientConfig = () => async (dispatch) => {
	try {
		const response = await axios({
			url: `https://${config.s3Bucket}.s3.amazonaws.com/${config.s3Prefix}/${config.clientId}/layout/config.json`,
			method: "GET",
		});
		const clientConfig = response.data;
		dispatch({
			type: "CLIENT_CONFIG_FETCH_SUCCESS",
			payload: clientConfig,
		});
	} catch (error) {
		throw typeof error === "string"
			? error
			: "Unable to fetch global config file.";
	}
};

export const toggleSidebar = (displaySideBar) => ({
	type: "TOGGLE_SIDEBAR",
	payload: displaySideBar,
});

export const updateError = (errorMessage) => {
	return {
		type: "UPDATE_ERROR_MESSAGE",
		payload: errorMessage,
	};
};

export const updateSuccessMessage = (successMessage) => {
	return {
		type: "UPDATE_SUCCESS_MESSAGE",
		payload: successMessage,
	};
};