import React from 'react';
import axios from "axios";
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import store from "~/redux/store";
import { updateError } from "~/redux/actions/appConfig";
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Provider store={store}>
				<App />
			</Provider>
		</BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

try {
	/** Intercept outgoing requests and update loading indicator state. **/
	axios.interceptors.request.use(
		(config) => {
			store.dispatch(updateError(null));
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	/** Intercept all responses update loading indicator state. **/
	axios.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			store.dispatch(updateError(error.response.data.error));
			return Promise.reject(error);
		}
	);
} catch (error) {
	console.log(error);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
