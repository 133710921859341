import React, { useState, useEffect } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import {
	Grid,
	Box,
	Typography,
	MenuItem,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@material-ui/core";

import Header from "~/modules/Header";
import PageTitle from "~/modules/PageTitle";
import AgentOverview from "~/modules/AgentOverview";
import AddUpdateAgent from "~/modules/AddUpdateAgent";
import DaterangePicker from "~/components/DaterangePicker";
import TextField from "~/components/Forms/TextField";
import Table from "~/components/Table";
import Button from "~/components/Forms/Button";

import {
	fetchAgent,
	updateAgent,
	blockAgent,
	unBlockAgent,
} from "~/redux/helpers/agent";
import {
	fetchAgentTransactionSummary,
	updatePaymentForAgent,
} from "~/redux/helpers/transaction";

import useStyles from "./styles";

export default function LotteryDetails() {
	const classes = useStyles();
	const { agentId } = useParams();
	const [pending, setPending] = useState(true);
	const [isMakePaymentDialogOpen, setIsMakePaymentDialogOpen] = useState(
		false
	);
	const [selectedPaymentDate, setSelectedPaymentDate] = useState(null);
	const [selectedPaymentAmount, setSelectedPaymentAmount] = useState(null);
	const [
		selectedPaymentTransactionType,
		setSelectedPaymentTransactionType,
	] = useState("PAYMENT_RECEIVED");
	const [agentTransactionPending, setAgentTransactionPending] = useState(
		false
	);
	const [updateAgentProgress, setUpdateAgentProgress] = useState(false);
	const [blockUnblockPending, setBlockUnblockPending] = useState(false);

	const [dateRange, setDateRange] = useState({
		startDate: moment().startOf("day").valueOf(),
		endDate: moment().endOf("day").valueOf(),
	});
	const [agent, setAgent] = useState({});
	const [transactions, setTransactions] = useState([]);
	const [agentConfiguration, setAgentConfiguration] = useState({});
	const [isOpen, setIsOpen] = useState(false);

	const columns = [
		{ id: "date", label: "Date" },
		{ id: "sales", label: "Sales" },
		{ id: "commissions", label: "Agent Comm." },
		{ id: "megapayCommissions", label: "Megapay Comm." },
		{ id: "rewards", label: "Rewards" },
		{ id: "amountToPay", label: "Amount to Pay" },
		{ id: "profit", label: "Net Profit" },
		{ id: "action", label: "Action" },
	];

	const getAgetTransactionSummary = () => {
		setAgentTransactionPending(true);
		fetchAgentTransactionSummary(
			agentId,
			dateRange.startDate,
			dateRange.endDate
		)
			.then((response) => {
				setTransactions(
					response.transactionSummary.map(
						({
							sales,
							commissions,
							megapayCommissions,
							rewards,
							paymentReceivedFromAgent,
							paymentMadeToMegapay,
							paymentMadeToAgent,
							...rest
						}) => ({
							pending: false,
							sales: sales,
							commissions: commissions,
							megapayCommissions: megapayCommissions,
							rewards: rewards || 0,
							paymentReceivedFromAgent: paymentReceivedFromAgent,
							paymentMadeToMegapay: paymentMadeToMegapay,
							paymentMadeToAgent: paymentMadeToAgent,
							amountToPay:
								commissions + rewards - paymentMadeToAgent,
							profit:
								sales -
								commissions -
								megapayCommissions -
								rewards,
							pendingBalanceAgent:
								sales -
								commissions -
								rewards -
								paymentReceivedFromAgent +
								paymentMadeToAgent,
							...rest,
						})
					)
				);
			})
			.catch((error) => console.log(error))
			.finally(() => {
				setAgentTransactionPending(false);
			});
	};

	const getAgentDetails = (agentId) => {
		fetchAgent(agentId)
			.then((response) => {
				setAgent(response.agent);
				setAgentConfiguration(response.agentConfiguration);
			})
			.catch((error) => console.log(error))
			.finally(() => {
				setPending(false);
			});
	};

	const updateAgentInfo = (agent) => {
		setUpdateAgentProgress(true);
		updateAgent(agentId, {
			...agent,
			password: agent.password === "" ? null : agent.password,
		})
			.then(() => {
				setIsOpen(false);
				getAgentDetails(agentId);
			})
			.catch((error) => console.log(error))
			.finally(() => setUpdateAgentProgress(false));
	};

	const blockUnblock = () => {
		setBlockUnblockPending(true);
		if (agent.status === "ACTIVE") {
			blockAgent(agentId).finally(() => {
				setIsOpen(false);
				setBlockUnblockPending(false);
				getAgentDetails(agentId);
			});
		} else {
			unBlockAgent(agentId).finally(() => {
				setIsOpen(false);
				setBlockUnblockPending(false);
				getAgentDetails(agentId);
			});
		}
	};

	const handleDateRangeChange = (dateRange) => {
		setDateRange({
			startDate: dateRange[0],
			endDate: dateRange[1],
		});
	};

	const updatePayment = () => {
		setTransactions(
			transactions.map((item) =>
				item.date === selectedPaymentDate
					? {
							...item,
							pending: true,
					  }
					: item
			)
		);
		updatePaymentForAgent(
			agentId,
			moment(selectedPaymentDate).startOf("day").valueOf(),
			moment(selectedPaymentDate).endOf("day").valueOf(),
			selectedPaymentTransactionType,
			selectedPaymentAmount * 100
		)
			.then((response) => {
				setSelectedPaymentTransactionType(null);
				setIsMakePaymentDialogOpen(false);
				getAgetTransactionSummary();
			})
			.catch((error) => console.log(error))
			.finally(() => {
				setTransactions(
					transactions.map((item) =>
						item.agentId === agentId
							? {
									...item,
									pending: false,
							  }
							: item
					)
				);
			});
	};

	const renderAction = ({ date, pendingBalanceAgent, megapayCommissions, pending }) => {
		return Math.abs(pendingBalanceAgent) !== 0 ? (
			<Button
				pending={pending}
				onClick={() => {
					setSelectedPaymentDate(date);
					setIsMakePaymentDialogOpen(true);
				}}
				color="primary"
				variant="contained"
			>
				Update Payment
			</Button>
		) : null;
	};

	useEffect(() => {
		getAgentDetails(agentId);
	}, [agentId]);

	useEffect(() => {
		getAgetTransactionSummary();
	}, [dateRange]);

	const rows = transactions.map(
		({
			sales,
			commissions,
			megapayCommissions,
			rewards,
			paymentReceivedFromAgent,
			paymentMadeToMegapay,
			paymentMadeToAgent,
			date,
			pendingBalanceAgent,
			amountToPay,
			profit,
			pending,
		}) => ({
			date: moment(parseInt(date)).format("DD MMM, YYYY"),
			sales: sales / 100,
			commissions: commissions / 100,
			megapayCommissions: megapayCommissions / 100,
			rewards: (rewards || 0) / 100,
			paymentReceivedFromAgent: paymentReceivedFromAgent / 100,
			paymentMadeToAgent: paymentMadeToAgent / 100,
			paymentMadeToMegapay: paymentMadeToMegapay / 100,
			profit: profit / 100,
			amountToPay: amountToPay / 100,
			pendingBalanceAgent: pendingBalanceAgent / 100,
			action: renderAction({
				date,
				pendingBalanceAgent,
				megapayCommissions,
				pending,
			}),
		})
	);

	return (
		<>
			<Header />
			<PageTitle
				title="Agent Details"
				showAction={false}
				rightElement={
					<DaterangePicker
						startDate={dateRange.startDate}
						endDate={dateRange.endDate}
						onChange={handleDateRangeChange}
					/>
				}
			/>
			<Box className={classes.contentWrapper}>
				<AgentOverview
					pending={pending}
					agentTransactionPending={agentTransactionPending}
					transactions={transactions}
					agent={{
						...agent,
						commission: agentConfiguration.commission,
					}}
					onEditAgent={() => setIsOpen(true)}
				/>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box className={classes.sectionHeader}>
							<Typography variant="h1">Transactions</Typography>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Table
							columns={columns}
							rows={rows}
							pending={agentTransactionPending}
						/>
					</Grid>
				</Grid>
			</Box>
			{!pending && (
				<AddUpdateAgent
					pending={updateAgentProgress}
					isOpen={isOpen}
					agent={{
						...agent,
						commission: agentConfiguration.commission,
					}}
					onClose={() => setIsOpen(false)}
					onBlockUnblock={blockUnblock}
					actionProgress={blockUnblockPending}
					onAction={updateAgentInfo}
				/>
			)}
			<Dialog
				open={isMakePaymentDialogOpen}
				onClose={() => setIsMakePaymentDialogOpen(false)}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					Update Payment Details
				</DialogTitle>
				<DialogContent className={classes.dialog}>
					<TextField
						select
						type="select"
						label="Payment Type"
						value={selectedPaymentTransactionType}
						onChange={(e) =>
							setSelectedPaymentTransactionType(e.target.value)
						}
					>
						<MenuItem value={null}></MenuItem>
						<MenuItem value={"PAYMENT_RECEIVED"}>
							Amount Received From Agent
						</MenuItem>
						<MenuItem value={"PAYMENT_MADE"}>
							Amount Paid To Agent
						</MenuItem>
					</TextField>
					<br />
					<TextField
						autoFocus
						margin="dense"
						label="Enter Amount"
						onChange={(e) =>
							setSelectedPaymentAmount(e.target.value)
						}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setIsMakePaymentDialogOpen(false)}
						color="primary"
						variant="outlined"
					>
						Cancel
					</Button>
					<Button
						onClick={updatePayment}
						color="primary"
						variant="contained"
					>
						Make Payment
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
