import axios from "axios";
import config from "~/config";

export const getAccessToken = async () => {
	const accessToken = window.localStorage.getItem("@accessToken");
	if (accessToken) {
		return accessToken;
	}
	return null
};
