import React, { useState, useEffect } from "react";
import moment from "moment";
import { Box, Grid, Typography, CircularProgress } from "@material-ui/core";
import Card from "~/components/Card";
import Loader from "~/components/Loader";
import Button from "~/components/Forms/Button";

import useStyles from "./styles";

const getRandomAmount = (min, max) => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min) + min) * 100;
};

export default ({
	pending,
	agent,
	onEditAgent,
	agentTransactionPending,
	transactions,
}) => {
	const classes = useStyles();
	const [salesSummary, setSalesSummary] = useState({
		totalSales: 0,
		totalCommissions: 0,
		totalRewards: 0,
		totalAmountPaid: 0,
		totalPaymentReceivedFromAgent: 0,
		totalPaymentMadeToAgent: 0,
		totalAmountReceived: 0,
		totalPendingBalanceAgent: 0,
		totalProfit: 0,
	});

	
	useEffect(() => {
		console.log(transactions);
		let totalSales = 0,
			totalRewards = 0,
			totalCommissions = 0,
			totalMegapayCommissions = 0,
			totalAmountPaid = 0,
			totalAmountReceived = 0,
			totalPaymentMadeToAgent = 0,
			totalPaymentReceivedFromAgent = 0,
			totalProfit = 0,
			totalPendingBalanceAgent = 0;
		transactions.map((transaction) => {
			totalSales += transaction.sales;
			totalRewards += transaction.rewards;
			totalCommissions += transaction.commissions;
			totalPaymentReceivedFromAgent +=
				transaction.paymentReceivedFromAgent;
			totalPaymentMadeToAgent += transaction.paymentMadeToAgent;
			totalMegapayCommissions += transaction.megapayCommissions;
			totalPendingBalanceAgent += transaction.pendingBalanceAgent;
			totalProfit += transaction.profit;
			totalAmountPaid += transaction.paymentMade;
			totalAmountReceived += transaction.paymentReceived;
		});
		setSalesSummary({
			totalSales,
			totalRewards,
			totalCommissions,
			totalMegapayCommissions,
			totalPaymentMadeToAgent,
			totalPaymentReceivedFromAgent,
			totalPendingBalanceAgent,
			totalProfit,
			totalAmountPaid,
			totalAmountReceived,
		});
	}, [transactions]);

	if (pending) {
		return <Loader />;
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={4}>
				<Card
					title="Basic Information"
					fontIcon="fa-info-circle"
					actionIcon="fa-pencil"
					onAction={onEditAgent}
				>
					<Box className={classes.container}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography variant="h4">Agent ID:</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="body2">
									{agent._id}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography variant="h4">Name:</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="body2">
									{`${agent.firstName} ${agent.lastName}`}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography variant="h4">Email:</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="body2">
									{`${agent.email}`}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography variant="h4">
									Phone Number:
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="body2">
									{agent.phoneNumber}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography variant="h4">
									Date of Birth
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="body2">
									{moment(
										`${agent.dob.date} ${agent.dob.month} ${agent.dob.year}`,
										"D M YYYY"
									).format("DD MMM, YYYY")}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography variant="h4">Joined On:</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="body2">
									{moment(agent.createdAt).format(
										"DD MMM, YYYY"
									)}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography variant="h4">Status:</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="body2">
									{agent.status}
								</Typography>
							</Grid>
						</Grid>
					</Box>
				</Card>
			</Grid>
			<Grid item xs={12} md={4}>
				<Card
					title="Commission & Bank Account Info"
					fontIcon="fa-money"
					actionIcon="fa-pencil"
					onAction={onEditAgent}
					className={classes.fullHeight}
				>
					<Box className={classes.container}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography variant="h4">
									Account Number:
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="body2">
									{agent.bankAccount[0].accountNumber}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography variant="h4">Bank Name:</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="body2">
									{agent.bankAccount[0].bankName}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography variant="h4">IFSC Code:</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="body2">
									{agent.bankAccount[0].ifscCode}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography variant="h4">
									Commission:
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="body2">
									{`${agent.commission}%`}
								</Typography>
							</Grid>
						</Grid>
					</Box>
				</Card>
			</Grid>

			<Grid item xs={12} md={4}>
				<Card
					title="Sale Summary"
					fontIcon="fa-money"
					className={classes.fullHeight}
				>
					{agentTransactionPending ? (
						<Loader />
					) : (
						<Box className={classes.container}>
							<Grid container spacing={2}>
								<Grid item xs={7}>
									<Typography variant="h4">
										Total Sales:
									</Typography>
								</Grid>
								<Grid item xs={5}>
									<Typography variant="body2">
										{salesSummary.totalSales / 100}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={2}>
								<Grid item xs={7}>
									<Typography variant="h4">
										Agent Commission:
									</Typography>
								</Grid>
								<Grid item xs={5}>
									<Typography variant="body2">
										{salesSummary.totalCommissions / 100}
									</Typography>
								</Grid>
							</Grid>
							{/* <Grid container spacing={2}>
								<Grid item xs={6}>
									<Typography variant="h4">
										Megapay Commission:
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="body2">
										{salesSummary.totalMegapayCommissions /
											100}
									</Typography>
								</Grid>
							</Grid> */}
							<Grid container spacing={2}>
								<Grid item xs={7}>
									<Typography variant="h4">
										Total Rewards:
									</Typography>
								</Grid>
								<Grid item xs={5}>
									<Typography variant="body2">
										{salesSummary.totalRewards / 100}
									</Typography>
								</Grid>
							</Grid>
							{/* <Grid container spacing={2}>
								<Grid item xs={6}>
									<Typography variant="h4">
										Total Amount Paid:
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="body2">
										{salesSummary.totalAmountPaid / 100}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<Typography variant="h4">
										Total Amount Received:
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography variant="body2">
										{salesSummary.totalAmountReceived / 100}
									</Typography>
								</Grid>
							</Grid> */}
							<Grid
								container
								spacing={2}
								className={classes.borderTop}
							>
								<Grid item xs={7}>
									<Typography variant="h4">
										Amount paid to Agent:
									</Typography>
								</Grid>
								<Grid item xs={5}>
									<Typography variant="body2">
										{salesSummary.totalPaymentMadeToAgent /
											100}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={2}>
								<Grid item xs={7}>
									<Typography variant="h4">
										Amount received from Agent:
									</Typography>
								</Grid>
								<Grid item xs={5}>
									<Typography variant="body2">
										{salesSummary.totalPaymentReceivedFromAgent /
											100}
									</Typography>
								</Grid>
							</Grid>
							{/* <Grid container spacing={2}>
								<Grid item xs={7}>
									<Typography variant="h4">
										Pending Agent Balance:
									</Typography>
								</Grid>
								<Grid item xs={5}>
									<Typography variant="body2">
										{salesSummary.totalPendingBalanceAgent /
											100}
									</Typography>
								</Grid>
							</Grid> */}
							<Grid
								container
								spacing={2}
								className={classes.borderTop}
							>
								<Grid item xs={7}>
									<Typography variant="h4">
										Net Profit:
									</Typography>
								</Grid>
								<Grid item xs={5}>
									<Typography variant="body2">
										{salesSummary.totalProfit / 100}
									</Typography>
								</Grid>
							</Grid>
						</Box>
					)}
				</Card>
			</Grid>
		</Grid>
	);
};
