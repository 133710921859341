import React from "react";
import { Grid } from "@material-ui/core";
import InfoCard from '~/components/InfoCard';

const colors = [
	{
		background: "#4eb9a8",
		contrast: "#fff",
	},
	{
		background: "#73c18d",
		contrast: "#fff",
	},
	{
		background: "#f2fbbd",
		contrast: "#444",
	},
	{
		background: "#ed3b59",
		contrast: "#fff",
	},
	{
		background: "#5f6b17",
		contrast: "#fff",
	},
];

export default function InfoSummary(props) {
	const { data, pending } = props;
	return (
		<Grid container spacing={3}>
			{data.map((item, index) => (
				<Grid key={`info-${index}`} item xs={12} sm={6} md={3}>
					<InfoCard data={item} colorScheme={colors[index]} pending={pending} />
				</Grid>
			))}
		</Grid>
	);
}
