import axios from "axios";
import config from "~/config";
import { getAccessToken } from "~/redux/helpers";

export const fetchBorletteConfiguration = async () => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/borlette/config`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};

export const updateBorletteConfiguration = async (data) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/borlette/config`,
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
			data: JSON.stringify(data),
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};

export const fetchBorletteList = async ({ limit, offset }) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/borlette`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
			params: {
				limit,
				offset,
			},
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};

export const fetchBorlette = async (borletteId) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/borlette/${borletteId}`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};

export const fetchBorletteTicket = async (borletteId, agentId, limit, offset) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/borlette/tickets/${
				borletteId ? borletteId : ""
			}`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
			params: {
				agentId,
				limit,
				offset,
			},
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};

export const deleteBorletteTicket = async (ticketId) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/borlette/ticket/delete/${ticketId}`,
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};

export const updateLottery = async (borletteId, data) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/borlette/${borletteId}`,
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
			data: JSON.stringify(data),
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};

export const previewResult = async (borletteId, data) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/borlette/preview/${borletteId}`,
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
			data: JSON.stringify(data),
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};

export const publishResult = async (borletteId, data) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/borlette/publish/${borletteId}`,
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
			data: JSON.stringify(data),
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};

export const getBorletteNumberBalance = async (borletteId) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/borlette/balance/${borletteId}`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			}
		});
		return await response.data;
	} catch (error) {
		return {
			error,
		};
	}
};