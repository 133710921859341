import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	container: {
		padding: "1rem",
	},
	resultContainer: {
		padding: "2rem 1rem",
	},
	resultBallContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	resultBall: {
		width: "50px",
		height: "50px",
		backgroundColor: theme.palette.primary.main,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: "50%",
		color: theme.palette.primary.contrastText,
		boxShadow:
			"0 5px 5px 0 rgba(0,0,0,0.14), 0 5px 10px 6px rgba(0,0,0,0.12), 0 5px 9px 0 rgba(0,0,0,0.2)",
	},
	borderTop: {
		borderTop: `1px solid ${theme.palette.border.main}`,
		marginTop: "0.5rem",
	},
	sectionHeader: {
		padding: "1rem 0rem",
	},
	fullHeight: {
		height: "100%",
	},
	dialog: {
		width: "500px",
	},
}));
