import React from "react";
import { CircularProgress, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
	loader: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	button: {
		boxShadow: "0 1px 5px 0 rgba(0, 0, 0, 0.19)",
		padding: "0.75rem 1rem",
		border: "none",
		display: "flex",
		alignItems: "center",
		gap: "0.75rem",
		outline: "none",
		cursor: "pointer",
	},
	rounded: {
		borderRadius: "1.5rem",
	},
	fullWidth: {
		width: "100%",
		justifyContent: "center",
	},
	smallButtonSize: {
		padding: "0.75rem 0.5rem",
	},
	primarycontained: {
		...theme.typography.h5,
		backgroundColor: `${theme.palette.primary.main} !important`,
		color: theme.palette.primary.contrastText,
	},
	primaryoutlined: {
		...theme.typography.h5,
		backgroundColor: "transparent !important",
		border: `1px solid ${theme.palette.primary.main} !important`,
		color: theme.palette.primary.main,
	},
	primarytext: {
		border: `none`,
		color: theme.palette.primary.main,
	},
	errorcontained: {
		...theme.typography.h5,
		backgroundColor: `${theme.palette.error.main} !important`,
		color: theme.palette.error.contrastText,
	},
	erroroutlined: {
		...theme.typography.h5,
		backgroundColor: "transparent !important",
		border: `1px solid ${theme.palette.error.main} !important`,
		color: theme.palette.error.main,
	},
	errortext: {
		border: `none`,
		color: theme.palette.error.main,
	},
}));

export default function Button(props) {
	const classes = useStyles();
	const { className, rounded, fullWidth, pending, size, ...restProps } = props;
	if (pending) {
		return (
			<Box
				className={clsx(
					classes.loader,
					fullWidth ? classes.fullWidth : "",
					className
				)}
			>
				<CircularProgress />
			</Box>
		);
	}
	return (
		<button
			variant={props.variant || "contained"}
			className={clsx(
				classes.button,
				classes[`${props.color}${props.variant}`],
				rounded ? classes.rounded : "",
				fullWidth ? classes.fullWidth : "",
				size === "small" ? classes.smallButtonSize : "",
				className
			)}
			onClick={props.onClick}
			{...restProps}
		>
			{props.children}
		</button>
	);
};
