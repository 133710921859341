import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
	Drawer,
	Typography,
	Box,
	Icon,
	useTheme,
	useMediaQuery,
} from "@material-ui/core";
import Button from "~/components/Forms/Button";

const useStyles = makeStyles((theme) => ({
	sidePanleWrapper: {
		width: "75%",
		height: "100%",
		[theme.breakpoints.up("xs")]: {
			width: "100%",
		},
		[theme.breakpoints.up("sm")]: {
			width: "40%",
			minWidth: "450px",
		},
	},
	contentTitleWrapper: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "0.55rem",
		zIndex: 99,
		background: theme.palette.background.header,
		boxShadow:
			"0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)",
	},
	contentTitleContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	closeIcon: {
		cursor: "pointer",
		marginRight: "1rem",
	},
	contentWrapper: {
		padding: "0.5rem",
	},
}));


export default function SidePanel(props) {
	const {
		title,
		children,
		isOpen,
		onClose,
		actionTitle,
		onAction,
		actionType,
		actionIcon,
		showAction,
		actionProgress,
	} = props;
	const classes = useStyles();
	const theme = useTheme();
	const isFullScreen = useMediaQuery(theme.breakpoints.down("xs"));

	return (
		<Drawer
			anchor={isFullScreen ? "bottom" : "right"}
			open={isOpen}
			// variant="persistent"
			classes={{
				paper: classes.sidePanleWrapper,
			}}
		>
			<Box className={classes.container}>
				<Box className={classes.contentTitleWrapper}>
					<Box className={classes.contentTitleContainer}>
						<Icon
							onClick={onClose}
							className={clsx(
								"fa fa-2x",
								"fa-times",
								classes.closeIcon
							)}
						/>
						<Typography variant="h6">{title}</Typography>
					</Box>
					{showAction ? (
						<Box>
							<Button
								className={classes.fixPositioned}
								onClick={onAction}
								color={
									actionType === "danger"
										? "error"
										: "primary"
								}
								pending={actionProgress}
								variant="contained"
							>
								{actionIcon ? (
									<Icon
										onClick={onAction}
										className={clsx(
											"fa",
											actionIcon,
											classes.fixPositioned
										)}
									/>
								) : null}
								{actionTitle}
							</Button>
						</Box>
					) : null}
				</Box>
				<Box className={classes.contentWrapper}>{children}</Box>
			</Box>
		</Drawer>
	);
}
