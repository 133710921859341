import React, { useState, useEffect } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import {
	IconButton,
	Icon,
	Grid,
	Box,
	CircularProgress,
} from "@material-ui/core";

import Table from "~/components/Table";
import Loader from "~/components/Loader";
import SelectSearch from "~/components/Forms/SelectSearch";
import ViewTicketDetails from "~/modules/ViewTicketDetails";

import { fetchAgentList } from "~/redux/helpers/agent";
import {
	fetchBorletteTicket,
	deleteBorletteTicket,
} from "~/redux/helpers/borlette";

export default ({ borletteDetails }) => {
	const { borletteId } = useParams();
	const [pendingTickets, setPendingTickets] = useState(true);
	const [pendingDeleteTicket, setPendingDeleteTicket] = useState(false);
	const [agentList, setAgentList] = useState([]);
	const [selectedAgent, setSelectedAgent] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const [selectedTicket, setSelectedTicket] = useState({});
	const [borletteTickets, setBorletteTickets] = useState({});
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [count, setCount] = useState(0);
	const columns = [
		{ id: "purchasedOn", label: "Purchased On"},
		{ id: "_id", label: "Ticket ID" },
		{ id: "agentName", label: "Agent" },
		{ id: "totalAmountPlayed", label: "Amount Paid" },
		{ id: "totalAmountWon", label: "Rewards"},
		{ id: "isCashout", label: "Cashout"},
		{ id: "status", label: "Status"},
		{ id: "action", label: "Action"},
	];

	const getBorletteTickets = (borletteId, agentId, limit, offset) => {
		setPendingTickets(true);
		fetchBorletteTicket(borletteId, agentId, limit, offset)
			.then((response) => {
				const { borletteTicketList, count } = response;
				setBorletteTickets(
					borletteTicketList.map((ticket) => ({
						...ticket,
						agentName: `${ticket.agent.firstName} ${ticket.agent.lastName}`,
						totalAmountPlayed: ticket.totalAmountPlayed / 100,
						totalAmountWon: (ticket.totalAmountWon || 0) / 100,
						isCashout: ticket.isAmountDisbursed ? "Yes" : "No",
						purchasedOn: moment(ticket.createdAt).format(
							"DD MMM, YYYY HH:mm A"
						),
						numbers: ticket.numbers.map(
							({ numberPlayed, amountPlayed, amountWon }) => ({
								numberPlayed,
								amountPlayed: amountPlayed / 100,
								amountWon: (amountWon || 0) / 100,
							})
						),
					}))
				);
				setCount(count);
			})
			.catch((error) => console.log(error))
			.finally(() => {
				setPendingTickets(false);
			});
	};

	const handleTicketClick = (ticket) => {
		setSelectedTicket(ticket);
		setIsOpen(true);
	};

	const processDeleteTicket = (ticket) => {
		setPendingDeleteTicket(true);
		deleteBorletteTicket(ticket._id)
			.then((response) => {
				if (response.error) {
					throw response.error;
				}
				setBorletteTickets(borletteTickets.filter(item => item._id !== ticket._id ));
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setPendingDeleteTicket(false);
			});
	};

	const renderAction = (ticket) => {
		return (
			<>
				<IconButton
					color="primary"
					aria-label="view ticket numbers"
					component="span"
					onClick={() => handleTicketClick(ticket)}
				>
					<Icon className={clsx("fa", "fa-eye")} />
				</IconButton>
				{ticket.status === "ACTIVE" &&
					(pendingDeleteTicket ? (
						<CircularProgress size={15} />
					) : (
						<IconButton
							color="secondary"
							aria-label="delete ticket"
							component="span"
							onClick={() => processDeleteTicket(ticket)}
						>
							<Icon className={clsx("fa", "fa-trash")} />
						</IconButton>
					))}
			</>
		);
	};

	const handleChangePage = (e, page) => {
		setPage(page + 1);
	};

	useEffect(() => {
		const limit = rowsPerPage;
		const offset = (page - 1) * rowsPerPage;
		getBorletteTickets(borletteId, selectedAgent, limit, offset);
	}, [borletteId, selectedAgent, rowsPerPage, page]);

	useEffect(() => {
		fetchAgentList({}).then((response) => {
			const { agentList } = response;
			setAgentList(agentList);
		});
	}, [borletteId]);

	const rows = borletteTickets.length
		? borletteTickets.map((ticket) => ({
				...ticket,
				totalAmountWon:
					borletteDetails.status === "SCHEDULED"
						? "NA"
						: ticket.totalAmountWon || 0,
				action: renderAction(ticket),
		  }))
		: [];

	if (pendingTickets) {
		return <Loader />;
	}
	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Box>
						<SelectSearch
							placeholder="Filter by Agent"
							options={agentList.map((agent) => ({
								label: `${agent.firstName} ${agent.lastName}`,
								value: agent._id,
							}))}
							value={selectedAgent}
							onChange={(selectedOption) =>
								setSelectedAgent(
									selectedOption ? selectedOption.value : null
								)
							}
						/>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Table
						columns={columns}
						rows={rows}
						count={count}
						pagination={true}
						rowsPerPage={rowsPerPage}
						page={page - 1}
						handleChangePage={handleChangePage}
					/>
				</Grid>
			</Grid>
			<ViewTicketDetails
				selectedTicket={selectedTicket}
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
			/>
		</>
	);
};
