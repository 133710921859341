import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Icon } from "@material-ui/core";

import Header from "~/modules/Header";
import PageTitle from "~/modules/PageTitle";
import AgentList from "~/modules/AgentList";
import AddUpdateAgent from "~/modules/AddUpdateAgent";

import { addAgent } from "~/redux/helpers/agent";

import useStyles from "./styles";

export default () => {
	const classes = useStyles();
	const [isOpen, setIsOpen] = useState(false);
	const [addAgentProgress, setAddAgentProgress] = useState(false);
	const [dataChanged, setDataChanged] = useState([]);

	const addNewAgent = (agent) => {
		setAddAgentProgress(true);
		addAgent(agent)
			.then(() => {
				setIsOpen(false);
				setDataChanged(!dataChanged);
			})
			.catch((error) => console.log(error))
			.finally(() => setAddAgentProgress(false));
	}
	
	return (
		<>
			<Header />
			<PageTitle
				title="Agents"
				showAction={true}
				actionTitle="Add New Agent"
				actionIcon={<Icon className="fa fa-plus"></Icon>}
				onAction={() => setIsOpen(true)}
			/>
			<Box className={classes.contentWrapper}>
				<AgentList dataChanged={dataChanged} />
			</Box>
			<AddUpdateAgent
				pending={addAgentProgress}
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				onAction={addNewAgent}
			/>
		</>
	);
};
