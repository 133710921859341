import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Icon } from "@material-ui/core";

import Header from "~/modules/Header";
import PageTitle from "~/modules/PageTitle";
import PlansList from "~/modules/PlansList";

import useStyles from "./styles";

export default function Plans(props) {
	const { type } = useParams();
	const classes = useStyles();
	const [isOpen, setIsOpen] = useState(false);
	return (
		<>
			<Header />
			<PageTitle
				title={`${type}'s Plan`}
				showAction={true}
				actionTitle="Create New Plan"
				actionIcon={<Icon className="fa fa-plus"></Icon>}
				onAction={() => setIsOpen(true)}
			/>
			<Box className={classes.contentWrapper}>
				<PlansList />
			</Box>
		</>
	);
}
