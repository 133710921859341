import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
	container: {
		padding: "1rem",
	},
	pageTitleWrapper: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center"
	},
	infoSummaryContainer: {
		padding: "1rem 0rem",
	},
	salesSummaryContainer: {
		padding: "1rem 0rem",
	},
});
