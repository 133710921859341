import axios from "axios";
import config from "~/config";
import { getAccessToken } from "~/redux/helpers";

export const getFranchiseSummary = async (startDate, endDate) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/franchise/summary`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
			params: {
				startDate,
				endDate,
			},
		});
		return await response.data;
	} catch (error) {
		return {
			error: error.response.data,
		};
	}
};
