import React, { useState, useEffect} from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import Loader from "~/components/Loader";
import Table from "~/components/Table";
import Graph from "~/components/Graph";
import Card from "~/components/Card";

import useStyles from "./styles";

const colors = [
	{
		background: "#70c0b3",
		contrast: "#fff",
	},
	{
		background: "#b3dbc0",
		contrast: "#fff",
	},
	{
		background: "#f2fbbd",
		contrast: "#444",
	},
	{
		background: "#eb557b",
		contrast: "#fff",
	},
];

export default function SalesSummary(props) {
	const { data, graphData, pending } = props;
	const classes = useStyles();
	const columns = [
		{ id: "agent", label: "Agent", minWidth: 170 },
		{ id: "sales", label: "Sales", minWidth: 170 },
		{ id: "commissions", label: "Commissions", minWidth: 170 },
		{ id: "megaPayCommissions", label: "MegaPay", minWidth: 170 },
		{ id: "rewards", label: "Rewards", minWidth: 170 },
		{ id: "balance", label: "Net Profit", minWidth: 170 },
	];
	const [chartConfig, setChartConfig] = useState({
		type: "doughnut",
		data: {
			datasets: [
				{
					data: graphData.map((item) => item / 100),
					backgroundColor: ["#b3dbc0", "#eb557b"],
					label: "Dataset 1",
				},
			],
			labels: ["Morning", "Evening"],
		},
		options: {
			responsive: true,
			legend: {
				position: "top",
			},
			title: {
				display: false,
				text: "General Sales",
			},
			animation: {
				animateScale: true,
				animateRotate: true,
			},
			circumference: 2 * Math.PI,
			rotation: -Math.PI / 2,
		},
	});
	useEffect(() => {
		setChartConfig({
			...chartConfig,
			data: {
				...chartConfig.data,
				datasets: [
					{
						backgroundColor: ["#b3dbc0", "#eb557b"],
						label: "Dataset 1",
						data: graphData.map((item) => item / 100),
					},
				],
			},
		});
	}, [graphData[0], graphData[1]]);
	const hasData = graphData.filter(item => item).length > 0;
	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={9} md={9}>
					<Card title="General Sales" fontIcon="fa-bar-chart">
						<Box>
							{pending ? (
								<Loader />
							) : hasData ? (
								<Graph {...chartConfig} />
							) : (
								<Box className={classes.centeredData} py={4}>
									<Typography
										variant="h2"
										className={classes.noData}
									>
										No records found
									</Typography>
								</Box>
							)}
						</Box>
					</Card>
				</Grid>
				<Grid item xs={12} sm={3} md={3}>
					<Card
						title="Activity Stream"
						fontIcon="fa-clock-o"
						className={classes.fullHeight}
					>
						<Box className={classes.fullHeight}></Box>
					</Card>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Card title="Sales Summary" fontIcon="fa-dollar">
						<Table
							columns={columns}
							rows={data}
							pending={pending}
						/>
					</Card>
				</Grid>
			</Grid>
		</>
	);
}
