import React, { useState } from "react";
import clsx from "clsx";
import { Box, Grid, MenuItem, Typography, Icon } from "@material-ui/core";

import NoData from "~/components/NoData";
import SidePanel from "~/components/SidePanel";
import Button from "~/components/Forms/Button";
import TextField from "~/components/Forms/TextField";

import useStyles from "./styles";

export default function AddUpdateLotterys(props) {
	const { isOpen, onClose, lottery } = props;
	const classes = useStyles();
	const [lotteryData, setLotteryData] = useState({
		lotteryName: "",
		amount: '',
		currency: "usd",
		interval: "",
		interval_count: '',
	});
	const [lotteryCommonRestrictions, setLotteryCommonRestrictions] = useState({
		twoDigit: "",
		threeDigit: "",
		fourDigit: "",
		fiveDigit: "",
	});

	const [lotteryCustomRestrictions, setLotteryCustomRestrictions] = useState([]);

	const addCustomRestrictions = () => {
		setLotteryCustomRestrictions([
			...lotteryCustomRestrictions,
			{
				number: "",
				value: "",
			},
		]);
	};

	const removeCustomRestrictions = (index) => {
		setLotteryCustomRestrictions(
			lotteryCustomRestrictions.filter((item, i) => i !== index)
		);
	};

	return (
		<SidePanel
			title={lottery ? "Update Lottery" : "Create New Lottery"}
			isOpen={isOpen}
			onClose={onClose}
		>
			<Box className={classes.contentContainer}>
				<Typography variant="h4" className={classes.sectionHeader}>
					Lottery Details
				</Typography>
				<TextField
					type="text"
					label="Lottery Name"
					name="lotteryName"
					value={lotteryData.lotteryName}
					onChange={(e) =>
						setLotteryData({
							...lotteryData,
							lotteryName: e.target.value,
						})
					}
				/>
				<TextField
					select
					type="select"
					label="Lottery Type"
					name="lotteryType"
					value={lotteryData.lotteryType}
					onChange={(e) =>
						setLotteryData({
							...lotteryData,
							lotteryType: e.target.value,
						})
					}
				>
					<MenuItem value={"maten"}>Maten</MenuItem>
					<MenuItem value={"aswe"}>Aswe</MenuItem>
				</TextField>
				<TextField
					id="datetime-local"
					label="Scheduled Time"
					type="datetime-local"
					defaultValue={new Date()}
					value={lotteryData.scheduledTime}
					InputLabelProps={{
						shrink: true,
					}}
					onChange={(e) =>
						setLotteryData({
							...lotteryData,
							scheduledTime: e.target.value,
						})
					}
				/>
				<Typography variant="h4" className={classes.sectionHeader}>
					Major Restriction
				</Typography>
				<Grid container spacing={2}>
					<Grid item xs={6} sm={3}>
						<TextField
							type="text"
							label="2 Digit"
							name="twoDigit"
							value={lotteryCommonRestrictions.twoDigit}
							onChange={(e) =>
								setLotteryCommonRestrictions({
									...lotteryCommonRestrictions,
									twoDigit: e.target.value,
								})
							}
						/>
					</Grid>
					<Grid item xs={6} sm={3}>
						<TextField
							type="text"
							label="3 Digit"
							name="threeDigit"
							value={lotteryCommonRestrictions.threeDigit}
							onChange={(e) =>
								setLotteryCommonRestrictions({
									...lotteryCommonRestrictions,
									threeDigit: e.target.value,
								})
							}
						/>
					</Grid>
					<Grid item xs={6} sm={3}>
						<TextField
							type="text"
							label="4 Digit"
							name="fourDigit"
							value={lotteryCommonRestrictions.fourDigit}
							onChange={(e) =>
								setLotteryCommonRestrictions({
									...lotteryCommonRestrictions,
									fourDigit: e.target.value,
								})
							}
						/>
					</Grid>
					<Grid item xs={6} sm={3}>
						<TextField
							type="text"
							label="5 Digit"
							name="fiveDigit"
							value={lotteryCommonRestrictions.fiveDigit}
							onChange={(e) =>
								setLotteryCommonRestrictions({
									...lotteryCommonRestrictions,
									fiveDigit: e.target.value,
								})
							}
						/>
					</Grid>
				</Grid>

				<Typography variant="h4" className={classes.sectionHeader}>
					Custom Restriction
				</Typography>
				<Box className={classes.container}>
					{lotteryCustomRestrictions.length === 0 ? (
						<NoData
							text="No Custom Restriction Added"
							actionTitle="Add Custom Restriction"
							onAction={addCustomRestrictions}
						/>
					) : (
						<>
							{lotteryCustomRestrictions.map((item, index) => (
								<Grid
									container
									spacing={2}
									key={`custom-restriction-${index}`}
									justify="center"
									alignItems="center"
								>
									<Grid item xs>
										<TextField
											type="text"
											label="Number"
											value={
												lotteryCustomRestrictions[index]
													.number
											}
											onChange={(e) =>
												setLotteryCustomRestrictions({
													lotteryCustomRestrictions: lotteryCustomRestrictions.map(
														(item, i) =>
															i === index
																? e.target.value
																: item.number
													),
												})
											}
										/>
									</Grid>
									<Grid item xs>
										<TextField
											type="text"
											label="Value"
											value={
												lotteryCustomRestrictions[index]
													.value
											}
											onChange={(e) =>
												setLotteryCustomRestrictions({
													lotteryCustomRestrictions: lotteryCustomRestrictions.map(
														(item, i) =>
															i === index
																? e.target.value
																: item.value
													),
												})
											}
										/>
									</Grid>
									<Grid item xs={1}>
										<Icon
											onClick={() =>
												removeCustomRestrictions(index)
											}
											className={clsx(
												"fa fa-2x",
												"fa-trash",
												classes.delete
											)}
										/>
									</Grid>
								</Grid>
							))}
							<Box py={2}>
								<Grid container spacing={2} justify="flex-end">
									<Grid item xs={4}>
										<Button
											onClick={addCustomRestrictions}
											color="primary"
											variant="outlined"
											fullWidth={200}
										>
											Add More
										</Button>
									</Grid>
									<Grid item xs={4}>
										<Button
											onClick={(e) => null}
											color="primary"
											variant="contained"
											fullWidth={200}
										>
											Save
										</Button>
									</Grid>
								</Grid>
							</Box>
						</>
					)}
				</Box>

				<Box className={classes.buttonContainer}>
					<Button
						onClick={onClose}
						color="primary"
						variant="outlined"
						fullWidth
					>
						Cancel
					</Button>
					<Button
						onClick={(e) => null}
						color="primary"
						variant="contained"
						fullWidth
					>
						Create
					</Button>
				</Box>
			</Box>
		</SidePanel>
	);
}
