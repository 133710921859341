import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { MenuItem, Grid, Box, Typography } from "@material-ui/core";
import TextField from "~/components/Forms/TextField";
import Table from "~/components/Table";
import moment from "moment";

import { fetchAgentList } from "~/redux/helpers/agent";

export default ({ dataChanged }) => {
	const history = useHistory();
	const [filter, setFilter] = useState({
		status: 'ACTIVE',
	});
	const [pending, setPending] = useState(true);
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [agentList, setAgentList] = useState([]);
	const [count, setCount] = useState(0);

	const columns = [
		{ id: "id", label: "ID", minWidth: 170 },
		{ id: "name", label: "Name", minWidth: 170 },
		{ id: "email", label: "Email", minWidth: 170 },
		{ id: "phone", label: "Phone Number", minWidth: 100 },
		{ id: "createdOn", label: "Registered On", minWidth: 100 },
		{ id: "status", label: "Status", minWidth: 100 },
	];
	const rows = agentList.map((item) => ({
		id: item._id,
		name: `${item.firstName} ${item.lastName}`,
		phone: item.phoneNumber,
		email: item.email,
		createdOn: moment(item.createdAt).format("DD MMM, YYYY"),
		status: item.status,
	}));

	const handleChangePage = (e, page) => {
		setPage(page + 1);
	};

	useEffect(() => {
		setPending(true);
		const limit = rowsPerPage;
		const offset = (page - 1) * rowsPerPage;
		fetchAgentList({
			limit,
			offset,
			...filter,
		})
			.then((response) => {
				if (response.error) {
					throw response.error;
				}
				const { agentList, count } = response;
				setAgentList(agentList);
				setCount(count);
			})
			.catch((error) => console.log(error))
			.finally(() => {
				setPending(false);
			});
	}, [page, filter, dataChanged]);
	return (
		<>
			<Grid container spacing={4}>
				<Grid item xs={12} sm={4}>
					<TextField
						select
						type="select"
						label="Filter By Status"
						value={filter.status}
						onChange={(e) =>
							setFilter({
								...filter,
								status: e.target.value,
							})
						}
					>
						<MenuItem value={null}>All</MenuItem>
						<MenuItem value={"ACTIVE"}>
							Active
						</MenuItem>
						<MenuItem value={"BLOCKED"}>
							Blocked
						</MenuItem>
					</TextField>
				</Grid>
				<Grid item xs={12}>
					<Table
						pending={pending}
						columns={columns}
						rows={rows}
						onRowClick={(item) => history.push(`/agent/${item.id}`)}
						count={count}
						pagination={true}
						rowsPerPage={rowsPerPage}
						page={page - 1}
						handleChangePage={handleChangePage}
					/>
				</Grid>
			</Grid>

		</>
	);
}
