import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import {
	Grid,
	Typography,
	Box,
	Paper,
	useTheme,
	useMediaQuery,
} from "@material-ui/core";

import Button from "~/components/Forms/Button";
import TextField from "~/components/Forms/TextField";

import { login } from "~/redux/actions/user"

import useStyles from "./styles";

const validationSchema = yup.object({
	email: yup.string().email("Email address is not valid").required("Email is required"),
	password: yup.string().required("Password is required"),
});

export default function () {
	const classes = useStyles();
	const [loginProgress, setLoginProgress] = useState(false)
	const dispatch = useDispatch();
	const processLogin = (values) => {
		setLoginProgress(true);
		dispatch(login(values)).finally((response) => setLoginProgress(false));
	};
	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: validationSchema,
		onSubmit: processLogin,
	});
	
	return (
		<Grid container spacing={0} className={classes.container}>
			<Grid item md={6}>
				<Box className={classes.lotteryBackground}></Box>
			</Grid>
			<Grid item xs={12} md={6}>
				<Box className={classes.loginBoxContainer}>
					<form
						noValidate
						autoComplete="off"
						onSubmit={formik.handleSubmit}
					>
						<Paper className={classes.loginBox}>
							<Grid
								container
								spacing={4}
								justify="center"
								align="center"
							>
								<Grid item xs={12}>
									<Typography variant="h2" color="primary">
										Login
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<TextField
										type="text"
										label="Email"
										name="email"
										value={formik.values.email}
										onChange={formik.handleChange}
										error={
											formik.touched.email &&
											Boolean(formik.errors.email)
										}
										helperText={
											formik.touched.email &&
											formik.errors.email
										}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										type="password"
										label="Password"
										name="password"
										value={formik.values.password}
										onChange={formik.handleChange}
										error={
											formik.touched.password &&
											Boolean(formik.errors.password)
										}
										helperText={
											formik.touched.password &&
											formik.errors.password
										}
									/>
								</Grid>
								<Grid item xs={12}>
									<Button
										type="submit"
										color="primary"
										variant="contained"
										className={classes.loginButton}
										pending={loginProgress}
									>
										Login
									</Button>
								</Grid>
							</Grid>
						</Paper>
					</form>
				</Box>
			</Grid>
		</Grid>
	);
}
