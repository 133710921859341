import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { Box, Icon, Grid, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";

import Loader from "~/components/Loader";
import Card from "~/components/Card";
import SelectSearch from "~/components/Forms/SelectSearch";
import TextField from "~/components/Forms/TextField";
import Button from "~/components/Forms/Button";
import NoData from "~/components/NoData";

import {
	fetchAgentList,
	fetchAgent,
	updateAgentConfiguration,
} from "~/redux/helpers/agent";
import { updateSuccessMessage } from "~/redux/actions/appConfig";

import useStyles from "./styles";

export default () => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [agentList, setAgentList] = useState([]);
	const [fetchingAgent, setFetchingAgent] = useState(true);
	const [
		fetchingAgentConfiguration,
		setFetchingAgentConfiguration,
	] = useState(false);
	const [pendingUpdateRestriction, setPendingUpdateRestriction] = useState(
		false
	);
	const [
		pendingUpdateRestrictionCustom,
		setPendingUpdateRestrictionCustom,
	] = useState(false);
	const [selectedAgent, setSelectedAgent] = useState(null);

	const addCustomRestrictions = () => {
		borletteCustomRestrictionFormik.setValues([
			...borletteCustomRestrictionFormik.values,
			{
				number: "",
				value: ""
			}
		]);
	};

	const removeCustomRestrictions = (index) => {
		borletteCustomRestrictionFormik.setValues(
			borletteCustomRestrictionFormik.values.filter((item, i) => i !== index)
		);
	};

	const updateMajorRestriction = () => {
		setPendingUpdateRestriction(true);
		handleUpdateAgentConfiguration()
			.then((response) => {
				if (!response.error) {
					dispatch(
						updateSuccessMessage(
							"Major restriction has been updated successfully."
						)
					);
				}
			})
			.finally(() => setPendingUpdateRestriction(false));
	}

	const updateCustomRestriction = () => {
		setPendingUpdateRestrictionCustom(true);
		handleUpdateAgentConfiguration()
			.then((response) => {
				if (!response.error) {
					dispatch(
						updateSuccessMessage(
							"Custom restriction has been updated successfully."
						)
					);
				}
			})
			.finally(() => setPendingUpdateRestrictionCustom(false));
	}

	const handleUpdateAgentConfiguration = () => {
		if (selectedAgent) {
			return updateAgentConfiguration(selectedAgent, {
				restrictions: {
					major: borletteMajorRestrictionFormik.values,
					custom: borletteCustomRestrictionFormik.values,
				},
			});
		} else {
			return Promise.resolve({});
		}
	};

	const borletteMajorRestrictionValidationSchema = useRef(
		yup.object({
			borlette: yup.number().positive().nullable(true),
			marriage: yup.number().positive().nullable(true),
			lotto3: yup.number().positive().nullable(true),
			lotto4: yup.number().positive().nullable(true),
			lotto5: yup.number().positive().nullable(true),
			lotto6: yup.number().positive().nullable(true),
		})
	);

	const borletteCustomRestrictionValidationSchema = useRef(
		yup.array().of(
			yup.object({
				number: yup.string().required().min(1),
				value: yup.number().required().min(0),
			})
		)
	);

	const borletteMajorRestrictionFormik = useFormik({
		initialValues: {
			borlette: null,
			marriage: null,
			lotto3: null,
			lotto4: null,
			lotto5: null,
			lotto6: null,
		},
		validationSchema: borletteMajorRestrictionValidationSchema.current,
		onSubmit: updateMajorRestriction,
	});

	const borletteCustomRestrictionFormik = useFormik({
		initialValues: [],
		validationSchema: borletteCustomRestrictionValidationSchema.current,
		onSubmit: updateCustomRestriction,
	});

	const getAgentList = () => {
		setFetchingAgent(true);
		fetchAgentList({})
			.then((response) => {
				const { agentList } = response;
				setAgentList(agentList);
			})
			.catch((error) => console.log(error))
			.finally(() => setFetchingAgent(false));
	};

	const handleSelectedAgent = ({ value }) => {
		setFetchingAgentConfiguration(true);
		fetchAgent(value)
			.then(({ agentConfiguration }) => {
				borletteMajorRestrictionFormik.setValues(
					Object.assign(
						{
							borlette: null,
							marriage: null,
							lotto3: null,
							lotto4: null,
							lotto5: null,
							lotto6: null,
						},
						agentConfiguration.restrictions.major
					)
				);
				borletteCustomRestrictionFormik.setValues(
					agentConfiguration.restrictions.custom
				);
				setSelectedAgent(value);
			})
			.catch((error) => {
				console.log(error);
				setSelectedAgent(null);
			})
			.finally(() => {
				setFetchingAgentConfiguration(false);
			});
	};

	useEffect(() => {
		if (
			!fetchingAgent &&
			!fetchingAgentConfiguration &&
			borletteCustomRestrictionFormik.values.length === 0
		) {
			updateCustomRestriction();
		}
	}, [borletteCustomRestrictionFormik.values.length]);

	useEffect(() => {
		getAgentList();
	}, []);

	if (fetchingAgent) {
		return <Loader />;
	}

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} md={12}>
					<Card title="Select Agent" fontIcon="fa-info-circle">
						<Box className={classes.container}>
							<SelectSearch
								options={agentList.map((agent) => ({
									label: `${agent.firstName} ${agent.lastName}`,
									value: agent._id,
								}))}
								value={selectedAgent}
								onChange={handleSelectedAgent}
							/>
						</Box>
					</Card>
				</Grid>
				{fetchingAgentConfiguration ? (
					<Loader />
				) : selectedAgent ? (
					<>
						<Grid item xs={12} md={12}>
							<form
								noValidate
								autoComplete="off"
								onSubmit={
									borletteMajorRestrictionFormik.handleSubmit
								}
							>
								<Card
									title="Major Restriction"
									fontIcon="fa-info-circle"
								>
									<Box className={classes.container}>
										<Grid container spacing={2}>
											<Grid item xs={6} sm={4}>
												<TextField
													type="text"
													label="Borlette"
													name="borlette"
													value={
														borletteMajorRestrictionFormik
															.values.borlette
													}
													onChange={
														borletteMajorRestrictionFormik.handleChange
													}
												/>
											</Grid>
											<Grid item xs={6} sm={4}>
												<TextField
													type="text"
													label="Marriage"
													name="marriage"
													value={
														borletteMajorRestrictionFormik
															.values.marriage
													}
													onChange={
														borletteMajorRestrictionFormik.handleChange
													}
												/>
											</Grid>
											<Grid item xs={6} sm={4}>
												<TextField
													type="text"
													label="Lotto 3"
													name="lotto3"
													value={
														borletteMajorRestrictionFormik
															.values.lotto3
													}
													onChange={
														borletteMajorRestrictionFormik.handleChange
													}
												/>
											</Grid>
										</Grid>
										<Grid container spacing={2}>
											<Grid item xs={6} sm={4}>
												<TextField
													type="text"
													label="Lotto 4"
													name="lotto4"
													value={
														borletteMajorRestrictionFormik
															.values.lotto4
													}
													onChange={
														borletteMajorRestrictionFormik.handleChange
													}
												/>
											</Grid>
											<Grid item xs={6} sm={4}>
												<TextField
													type="text"
													label="Lotto 5"
													name="lotto5"
													value={
														borletteMajorRestrictionFormik
															.values.lotto5
													}
													onChange={
														borletteMajorRestrictionFormik.handleChange
													}
												/>
											</Grid>
											<Grid item xs={6} sm={4}>
												<TextField
													type="text"
													label="Lotto 6"
													name="lotto6"
													value={
														borletteMajorRestrictionFormik
															.values.lotto6
													}
													onChange={
														borletteMajorRestrictionFormik.handleChange
													}
												/>
											</Grid>
										</Grid>
										<Box py={2}>
											<Grid
												container
												spacing={2}
												justify="flex-end"
											>
												<Grid item xs={2}>
													<Button
														className={
															classes.fixPositioned
														}
														pending={
															pendingUpdateRestriction
														}
														type="submit"
														onClick={(e) => null}
														color="primary"
														variant="contained"
														fullWidth
													>
														Update
													</Button>
												</Grid>
											</Grid>
										</Box>
									</Box>
								</Card>
							</form>
						</Grid>
						<Grid item xs={12} md={12}>
							<form
								noValidate
								autoComplete="off"
								onSubmit={
									borletteCustomRestrictionFormik.handleSubmit
								}
								>
								<Card
									title="Custom Restriction"
									fontIcon="fa-info-circle"
								>
									<Box className={classes.container}>
										{borletteCustomRestrictionFormik.values
											.length === 0 ? (
											<NoData
												text="No Custom Restriction Added"
												actionTitle="Add Custom Restriction"
												onAction={addCustomRestrictions}
											/>
										) : (
											<>
												{borletteCustomRestrictionFormik.values.map(
													(
														borletteCustomRestriction,
														index
													) => (
														<Grid
															container
															spacing={2}
															key={`custom-restriction-${index}`}
															justify="center"
															alignItems="center"
														>
															<Grid item xs>
																<TextField
																	type="text"
																	label="Number"
																	name={`${index}.number`}
																	value={
																		borletteCustomRestrictionFormik
																			.values[
																			index
																		].number
																	}
																	onChange={
																		borletteCustomRestrictionFormik.handleChange
																	}
																/>
															</Grid>
															<Grid item xs>
																<TextField
																	type="text"
																	label="Value"
																	name={`${index}.value`}
																	value={
																		borletteCustomRestrictionFormik
																			.values[
																			index
																		].value
																	}
																	onChange={
																		borletteCustomRestrictionFormik.handleChange
																	}
																/>
															</Grid>
															<Grid item xs={1}>
																<Icon
																	onClick={() =>
																		removeCustomRestrictions(
																			index
																		)
																	}
																	className={clsx(
																		"fa fa-2x",
																		"fa-trash",
																		classes.delete
																	)}
																/>
															</Grid>
														</Grid>
													)
												)}
												<Box py={2}>
													<Grid
														container
														spacing={2}
														justify="flex-end"
													>
														<Grid item xs={4} sm={2}>
															<Button
																onClick={
																	addCustomRestrictions
																}
																color="primary"
																variant="outlined"
																fullWidth={200}
															>
																Add More
															</Button>
														</Grid>
														<Grid item xs={3} sm={2}>
															<Button
																type="submit"
																pending={
																	pendingUpdateRestrictionCustom
																}
																onClick={(e) =>
																	null
																}
																color="primary"
																variant="contained"
																fullWidth={200}
															>
																Save
															</Button>
														</Grid>
													</Grid>
												</Box>
											</>
										)}
									</Box>
								</Card>									
							</form>
						</Grid>
					</>
				) : null}
			</Grid>
		</>
	);
};
