const initialState = window.initialState
	? {
			...window.initialState.appConfig,
			sidebarDisplayed: false,
			errorMessage: null,
			successMessage: null,
	  }
	: {
			sidebarDisplayed: false,
			errorMessage: null,
			successMessage: null,
	  };
export default function appConfig(state = initialState, action = {}) {
	switch (action.type) {
		case "CLIENT_CONFIG_FETCH_SUCCESS":
			return {
				...state,
				...action.payload,
				error: null,
			};
		case "CLIENT_CONFIG_FETCH_ERROR":
			return {
				...state,
				error: action.payload,
			};
		case "TOGGLE_SIDEBAR":
			return {
				...state,
				sidebarDisplayed: action.payload,
			};
		case "UPDATE_ERROR_MESSAGE":
			return {
				...state,
				errorMessage: action.payload,
			};
		case "UPDATE_SUCCESS_MESSAGE":
			return {
				...state,
				successMessage: action.payload,
			};
		default:
			return {
				...state,
			};
	}
}
