import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	cardHeader: {
		display: "flex",
		background: theme.palette.background.itemHeader,
		alignItems: "center",
		gap: "0.75rem",
		padding: "0.5rem",
	},
	fullHeight: {
		height: "100%",
	},
	centeredData: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	noData: {
		color: theme.palette.text.disabled,
	},
}));
