import React, { useState, useEffect } from "react";
import {
	Route,
	Switch,
	useHistory,
	useLocation,
	useRouteMatch,
	useParams,
	matchPath,
} from "react-router-dom";
import clsx from "clsx";
import { Grid, Box, Typography, Paper } from "@material-ui/core";

import Header from "~/modules/Header";
import PageTitle from "~/modules/PageTitle";
import LotteryOverview from "~/modules/LotteryOverview";
import PreviewLotteryResult from "~/modules/PreviewLotteryResult";
import LotteryTickets from "~/modules/LotteryTickets";
import BorletteTransactions from "~/modules/BorletteTransactions";
import LotteryMonitoring from "~/modules/LotteryMonitoring";

import { fetchBorlette, updateLottery } from "~/redux/helpers/borlette";
import { previewResult, publishResult } from "~/redux/helpers/borlette";

import useStyles from "./styles";

export default function LotteryDetails(props) {
	const classes = useStyles();
	const history = useHistory();
	const { borletteId } = useParams();
	const [previewProgress, setPreviewProgress] = useState(false);
	const [publishProgress, setPublishProgress] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0);
	const [countDown, setCountDown] = useState(0);
	const [isOpen, setIsOpen] = useState(false);
	const [previewResultData, setPreviewResultData] = useState({});
	const [results, setResults] = useState([]);


	
	const [pending, setPending] = useState(true);
	const [borletteDetails, setLotteryDetails] = useState({});
	const [updateLotteryProgress, setUpdateLotteryProgress] = useState(false);

	const getLotteryDetails = (borletteId) => {
		fetchBorlette(borletteId)
			.then((response) => {
				setLotteryDetails(response.borlette)
				setCountDown(response.countDown);
			})
			.catch((error) => console.log(error))
			.finally(() => {
				setPending(false);
			});
	};

	const updateLotteryInfo = (borletteDetails) => {
		setUpdateLotteryProgress(true);
		updateLottery(borletteId, borletteDetails)
			.then(() => {
				setIsOpen(false);
				getLotteryDetails(borletteId);
			})
			.catch((error) => console.log(error))
			.finally(() => setUpdateLotteryProgress(false));
	};

	const handleLotteryPreview = (results) => {
		setPreviewProgress(true);
		setResults(results);
		previewResult(borletteId, { results })
			.then((response) => {
				setPreviewResultData(response.preview);
				setIsOpen(true);
			})
			.catch((err) => console.error(err))
			.finally(() => {
				setPreviewProgress(false);
			});
	};

	const handleLotteryPublish = (results) => {
		setPublishProgress(true);
		publishResult(borletteId, { results })
			.then((response) => {
				setLotteryDetails(response.borlette);
				setIsOpen(true);
			})
			.catch((err) => console.error(err))
			.finally(() => {
				setPublishProgress(false);
				setIsOpen(false);
			});
	};

	
	const borletteDetailsTabs = [
		{
			title: "Overview",
			path: "/lottery/:borletteId",
			url: `/lottery/${borletteId}`,
			isActive: useRouteMatch("/lottery/:borletteId"),
		},
		{
			title: "Tickets",
			path: "/lottery/:borletteId/tickets",
			url: `/lottery/${borletteId}/tickets`,
			isActive: useRouteMatch("/lottery/:borletteId/tickets"),
		},
		{
			title: "Transactions",
			path: "/lottery/:borletteId/transactions",
			url: `/lottery/${borletteId}/transactions`,
			isActive: useRouteMatch("/lottery/:borletteId/transactions"),
		},
		{
			title: "Monitoring",
			path: "/lottery/:borletteId/monitoring",
			url: `/lottery/${borletteId}/monitoring`,
			isActive: useRouteMatch("/lottery/:borletteId/monitoring"),
		},
	];

	useEffect(() => {
		setActiveIndex(
			borletteDetailsTabs.findIndex((item) =>
				matchPath(history.location.pathname, {
					path: item.path,
					exact: true,
					strict: false,
				})
			)
		);
	}, [history.location.pathname]);

	useEffect(() => {
		getLotteryDetails(borletteId);
	}, [borletteId]);
	return (
		<>
			<Header />
			<PageTitle
				title="Lottery Details"
				showAction={false}
				tabs={borletteDetailsTabs.map((item) => item.title)}
				activeTabIndex={activeIndex}
				onTabChange={(e, index) => {
					history.push(borletteDetailsTabs[index].url);
				}}
			/>
			<Box className={classes.contentWrapper}>
				<Switch>
					<Route path="/lottery/:borletteId" exact={true}>
						<>
							<LotteryOverview
								pending={pending}
								borletteDetails={borletteDetails}
								countDown={countDown}
								previewProgress={previewProgress}
								publishProgress={publishProgress}
								onPreview={handleLotteryPreview}
								onPublish={handleLotteryPublish}
							/>
							<PreviewLotteryResult
								previewResultData={previewResultData}
								isOpen={isOpen}
								onClose={() => setIsOpen(false)}
								results={results}
								publishProgress={publishProgress}
								onPublish={handleLotteryPublish}
							/>
						</>
					</Route>
					<Route path="/lottery/:borletteId/tickets" exact={true}>
						<LotteryTickets borletteDetails={borletteDetails} />
					</Route>
					<Route
						path="/lottery/:borletteId/transactions"
						exact={true}
					>
						<BorletteTransactions />
					</Route>
					<Route path="/lottery/:borletteId/monitoring" exact={true}>
						<LotteryMonitoring />
					</Route>
				</Switch>
			</Box>
		</>
	);
}
