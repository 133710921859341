import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	container: {
		padding: "1rem",
	},
	loaderContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	borderTop: {
		borderTop: `1px solid ${theme.palette.border.main}`,
		marginTop: "0.5rem",
	},
	sectionHeader: {
		paddingBottom: "1rem",
	},
	fullHeight: {
		height: "100%",
	},
	delete: {
		color: theme.palette.error.main,
	},
}));
