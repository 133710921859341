import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Icon } from "@material-ui/core";

import Header from "~/modules/Header";
import PageTitle from "~/modules/PageTitle";
import LotteryList from "~/modules/LotteryList";
import AddUpdateLottery from "~/modules/AddUpdateLottery";

import useStyles from "./styles";

import { fetchBorletteList } from "~/redux/helpers/borlette";

export default (props) => {
	const { type } = useParams();
	const classes = useStyles();
	const [isOpen, setIsOpen] = useState(false);
	return (
		<>
			<Header />
			<PageTitle
				title="Lotteries"
				// tabs={["Borlette", "Megamillion"]}
				activeTabIndex={0}
				onTabChange={() => null}
				showAction={false}
				actionTitle="Create New Lottery"
				actionIcon={<Icon className="fa fa-plus"></Icon>}
				onAction={() => setIsOpen(true)}
			/>
			<Box className={classes.contentWrapper}>
				<LotteryList />
			</Box>
			<AddUpdateLottery
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
			/>
		</>
	);
}
