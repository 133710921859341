import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import moment from "moment";
import {
	Box,
	Icon,
	Grid,
	Typography,
} from "@material-ui/core";
import MomentUtils from "@material-ui/pickers/adapter/moment";
import { TimePicker, LocalizationProvider } from "@material-ui/pickers";
import { useFormik } from "formik";
import * as yup from "yup";

import Card from "~/components/Card";
import Loader from "~/components/Loader";
import TextField from "~/components/Forms/TextField";
import Button from "~/components/Forms/Button";
import NoData from "~/components/NoData";

import {
	fetchBorletteConfiguration,
	updateBorletteConfiguration,
} from "~/redux/helpers/borlette";
import { updateSuccessMessage } from "~/redux/actions/appConfig";

import useStyles from "./styles";

export default () => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [pending, setPending] = useState(true);
	const [
		pendingUpdateScheduledTime,
		setPendingUpdateScheduledTime,
	] = useState(false);
	const [pendingUpdateRewards, setPendingUpdateRewards] = useState(false);
	const [pendingUpdateRestriction, setPendingUpdateRestriction] = useState(
		false
	);
	const [
		pendingUpdateRestrictionCustom,
		setPendingUpdateRestrictionCustom,
	] = useState(false);
	const borletteRewardValidationSchema = useRef(
		yup.object({
			borlette: yup.object({
				first: yup
					.number()
					.required("Borlette first reward is required"),
				second: yup
					.number()
					.required("Borlette second reward is required"),
				third: yup
					.number()
					.required("Borlette third reward is required"),
			}),
			marriage: yup.number().required("Marriage reward is required"),
			lotto3: yup.number().required("Lotto3 reward is required"),
			lotto4: yup.number().required("Lotto4 reward is required"),
			lotto5: yup.number().required("Lotto5 reward is required"),
			lotto6: yup.number().required("Lotto6 reward is required"),
		})
	);
	const borletteMajorRestrictionValidationSchema = useRef(
		yup.object({
			borlette: yup.number().positive().nullable(true),
			marriage: yup.number().positive().nullable(true),
			lotto3: yup.number().positive().nullable(true),
			lotto4: yup.number().positive().nullable(true),
			lotto5: yup.number().positive().nullable(true),
			lotto6: yup.number().positive().nullable(true),
		})
	);

	const borletteCustomRestrictionValidationSchema = useRef(
		yup.array().of(
			yup.object({
				number: yup.string().required().min(1),
				value: yup.number().required().min(0),
			})
		)
	);
	const [borletteScheduledTime, setLotteryScheduledTime] = useState({
		am: "",
		pm: "",
	});
	const addCustomRestrictions = () => {
		borletteCustomRestrictionFormik.setValues([
			...borletteCustomRestrictionFormik.values,
			{
				number: "",
				value: "",
			},
		]);
	};

	const removeCustomRestrictions = (index) => {
		borletteCustomRestrictionFormik.setValues(
			borletteCustomRestrictionFormik.values.filter(
				(item, i) => i !== index
			)
		);
	};

	const updateMajorRestriction = () => {
		setPendingUpdateRestriction(true);
		handleUpdateBorletteConfiguration()
			.then((response) => {
				if (!response.error) {
					dispatch(
						updateSuccessMessage(
							"Major restriction has been updated successfully."
						)
					);
				}
			})
			.finally(() => setPendingUpdateRestriction(false));
	};

	const updateCustomRestriction = () => {
		setPendingUpdateRestrictionCustom(true);
		handleUpdateBorletteConfiguration()
			.then((response) => {
				if (!response.error) {
					dispatch(
						updateSuccessMessage(
							"Custom restrictions has been updated successfully."
						)
					);
				}
			})
			.finally(() => setPendingUpdateRestrictionCustom(false));
	};

	const handleUpdateScheduledTime = () => {
		setPendingUpdateScheduledTime(true);
		updateBorletteConfiguration({
			schedule: {
				am: parseInt(moment(borletteScheduledTime.am).format("Hmm")),
				pm: parseInt(moment(borletteScheduledTime.pm).format("Hmm")),
			},
		})
			.catch((error) => console.log(error))
			.finally(() => setPendingUpdateScheduledTime(false));
	};

	const handleUpdateBorletteReward = () => {
		setPendingUpdateRewards(true);
		updateBorletteConfiguration({
			rewards: borletteRewardFormik.values,
		})
			.then((response => {
				if (!response.error) {
					dispatch(
						updateSuccessMessage(
							"Borlette Reward has been updated successfully."
						)
					);
				}
			}))
			.catch((error) => console.log(error))
			.finally(() => setPendingUpdateRewards(false));
	};

	const handleUpdateBorletteConfiguration = () => {
		return updateBorletteConfiguration({
			restrictions: {
				major: borletteMajorRestrictionFormik.values,
				custom: borletteCustomRestrictionFormik.values,
			},
		});
	};

	const borletteRewardFormik = useFormik({
		initialValues: {
			borlette: { first: null, second: null, third: null },
			marriage: null,
			lotto3: null,
			lotto4: null,
			lotto5: null,
			lotto6: null,
		},
		validationSchema: borletteRewardValidationSchema.current,
		onSubmit: handleUpdateBorletteReward,
	});

	const borletteMajorRestrictionFormik = useFormik({
		initialValues: {
			borlette: null,
			marriage: null,
			lotto3: null,
			lotto4: null,
			lotto5: null,
			lotto6: null,
		},
		validationSchema: borletteMajorRestrictionValidationSchema.current,
		onSubmit: updateMajorRestriction,
	});

	const borletteCustomRestrictionFormik = useFormik({
		initialValues: [],
		validationSchema: borletteCustomRestrictionValidationSchema.current,
		onSubmit: updateCustomRestriction,
	});

	useEffect(() => {
		fetchBorletteConfiguration()
			.then((response) => {
				const {
					borletteConfiguration: {
						schedule: { am, pm },
					},
				} = response;
				borletteRewardFormik.setValues(
					response.borletteConfiguration.rewards
				);
				borletteMajorRestrictionFormik.setValues(
					response.borletteConfiguration.restrictions.major
				);
				borletteCustomRestrictionFormik.setValues(
					response.borletteConfiguration.restrictions.custom
				);
				setLotteryScheduledTime({
					am: am
						? new Date(
								moment(
									response.borletteConfiguration.schedule.am,
									"Hmm"
								)
						  )
						: "",
					pm: pm
						? new Date(
								moment(
									response.borletteConfiguration.schedule.pm,
									"Hmm"
								)
						  )
						: "",
				});
			})
			.catch((err) => console.error(err))
			.finally(() => setPending(false));
	}, []);

	useEffect(() => {
		if (!pending && borletteCustomRestrictionFormik.values.length === 0) {
			console.log(
				"borletteCustomRestrictionFormik.values.length => ",
				borletteCustomRestrictionFormik.values.length
			);
			updateCustomRestriction();
		}
	}, [borletteCustomRestrictionFormik.values.length]);

	if (pending) {
		return <Loader />;
	}
	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} md={12}>
					<Card title="Lottery Schedule" fontIcon="fa-clock-o">
						<Box className={classes.container}>
							<Grid container spacing={2}>
								<Grid item xs={6} sm={3}>
									<LocalizationProvider
										dateAdapter={MomentUtils}
									>
										<TimePicker
											disableCloseOnSelect
											label="AM Lottery Schedule"
											renderInput={(props) => (
												<TextField
													{...props}
													helperText=""
												/>
											)}
											okText="Done"
											value={borletteScheduledTime.am}
											onChange={(date) =>
												setLotteryScheduledTime({
													...borletteScheduledTime,
													am: date,
												})
											}
										/>
									</LocalizationProvider>
								</Grid>
								<Grid item xs={6} sm={3}>
									<LocalizationProvider
										dateAdapter={MomentUtils}
									>
										<TimePicker
											disableCloseOnSelect
											label="PM Lottery Schedule"
											renderInput={(props) => (
												<TextField
													{...props}
													helperText=""
												/>
											)}
											okText="Done"
											value={borletteScheduledTime.pm}
											onChange={(date) =>
												setLotteryScheduledTime({
													...borletteScheduledTime,
													pm: date,
												})
											}
										/>
									</LocalizationProvider>
								</Grid>
							</Grid>
							<Box py={2}>
								<Grid container spacing={2} justify="flex-end">
									<Grid item xs={2}>
										<Button
											pending={pendingUpdateScheduledTime}
											className={classes.fixPositioned}
											onClick={handleUpdateScheduledTime}
											color="primary"
											variant="contained"
											fullWidth
										>
											Update
										</Button>
									</Grid>
								</Grid>
							</Box>
						</Box>
					</Card>
				</Grid>
				<Grid item xs={12} md={12}>
					<Card title="Lottery Rewards" fontIcon="fa-trophy">
						<form
							noValidate
							autoComplete="off"
							onSubmit={borletteRewardFormik.handleSubmit}
						>
							<Box className={classes.container}>
								<Grid container spacing={2}>
									<Grid item xs={4} sm={4}>
										<Typography variant="h5">
											1st Prize
										</Typography>
									</Grid>
									<Grid item xs={4} sm={4}>
										<Typography variant="h5">
											2nd Prize
										</Typography>
									</Grid>
									<Grid item xs={4} sm={4}>
										<Typography variant="h5">
											3rd Prize
										</Typography>
									</Grid>
								</Grid>

								<Grid container spacing={2}>
									<Grid item xs={4} sm={4}>
										<TextField
											type="text"
											label="Borlette"
											name="borlette.first"
											value={
												borletteRewardFormik.values
													.borlette.first
											}
											onChange={
												borletteRewardFormik.handleChange
											}
											error={
												borletteRewardFormik.touched
													.borlette &&
												borletteRewardFormik.touched
													.borlette.first &&
												Boolean(
													borletteRewardFormik.errors
														.borlette &&
														borletteRewardFormik
															.errors.borlette
															.first
												)
											}
											helperText={
												borletteRewardFormik.touched
													.borlette &&
												borletteRewardFormik.touched
													.borlette.first &&
												borletteRewardFormik.errors
													.borlette &&
												borletteRewardFormik.errors
													.borlette.first
											}
										/>
									</Grid>
									<Grid item xs={4} sm={4}>
										<TextField
											type="text"
											label="Borlette"
											name="borlette.second"
											value={
												borletteRewardFormik.values
													.borlette.second
											}
											onChange={
												borletteRewardFormik.handleChange
											}
											error={
												borletteRewardFormik.touched
													.borlette &&
												borletteRewardFormik.touched
													.borlette.second &&
												Boolean(
													borletteRewardFormik.errors
														.borlette &&
														borletteRewardFormik
															.errors.borlette
															.second
												)
											}
											helperText={
												borletteRewardFormik.touched
													.borlette &&
												borletteRewardFormik.touched
													.borlette.second &&
												borletteRewardFormik.errors
													.borlette &&
												borletteRewardFormik.errors
													.borlette.second
											}
										/>
									</Grid>
									<Grid item xs={4} sm={4}>
										<TextField
											type="text"
											label="Borlette"
											name="borlette.third"
											value={
												borletteRewardFormik.values
													.borlette.third
											}
											onChange={
												borletteRewardFormik.handleChange
											}
											error={
												borletteRewardFormik.touched
													.borlette &&
												borletteRewardFormik.touched
													.borlette.third &&
												Boolean(
													borletteRewardFormik.errors
														.borlette &&
														borletteRewardFormik
															.errors.borlette
															.third
												)
											}
											helperText={
												borletteRewardFormik.touched
													.borlette &&
												borletteRewardFormik.touched
													.borlette.third &&
												borletteRewardFormik.errors
													.borlette &&
												borletteRewardFormik.errors
													.borlette.third
											}
										/>
									</Grid>
								</Grid>

								<Grid container spacing={2}>
									<Grid item xs={4} sm={4}>
										<TextField
											type="text"
											label="Mariage"
											name="marriage"
											value={
												borletteRewardFormik.values
													.marriage
											}
											onChange={
												borletteRewardFormik.handleChange
											}
											error={
												borletteRewardFormik.touched
													.marriage &&
												Boolean(
													borletteRewardFormik.errors
														.marriage
												)
											}
											helperText={
												borletteRewardFormik.touched
													.marriage &&
												borletteRewardFormik.errors
													.marriage
											}
										/>
									</Grid>
								</Grid>

								<Grid container spacing={2}>
									<Grid item xs={4} sm={4}>
										<TextField
											type="text"
											label="Lotto 3"
											name="lotto3"
											value={
												borletteRewardFormik.values
													.lotto3
											}
											onChange={
												borletteRewardFormik.handleChange
											}
											error={
												borletteRewardFormik.touched
													.lotto3 &&
												Boolean(
													borletteRewardFormik.errors
														.lotto3
												)
											}
											helperText={
												borletteRewardFormik.touched
													.lotto3 &&
												borletteRewardFormik.errors
													.lotto3
											}
										/>
									</Grid>
								</Grid>

								<Grid container spacing={2}>
									<Grid item xs={4} sm={4}>
										<TextField
											type="text"
											label="Lotto 4"
											name="lotto4"
											value={
												borletteRewardFormik.values
													.lotto4
											}
											onChange={
												borletteRewardFormik.handleChange
											}
											error={
												borletteRewardFormik.touched
													.lotto4 &&
												Boolean(
													borletteRewardFormik.errors
														.lotto4
												)
											}
											helperText={
												borletteRewardFormik.touched
													.lotto4 &&
												borletteRewardFormik.errors
													.lotto4
											}
										/>
									</Grid>
								</Grid>

								<Grid container spacing={2}>
									<Grid item xs={4} sm={4}>
										<TextField
											type="text"
											label="Lotto 5"
											name="lotto5"
											value={
												borletteRewardFormik.values
													.lotto5
											}
											onChange={
												borletteRewardFormik.handleChange
											}
											error={
												borletteRewardFormik.touched
													.lotto5 &&
												Boolean(
													borletteRewardFormik.errors
														.lotto5
												)
											}
											helperText={
												borletteRewardFormik.touched
													.lotto5 &&
												borletteRewardFormik.errors
													.lotto5
											}
										/>
									</Grid>
								</Grid>

								<Grid container spacing={2}>
									<Grid item xs={4} sm={4}>
										<TextField
											type="text"
											label="Lotto 6"
											name="lotto6"
											value={
												borletteRewardFormik.values
													.lotto6
											}
											onChange={
												borletteRewardFormik.handleChange
											}
											error={
												borletteRewardFormik.touched
													.lotto6 &&
												Boolean(
													borletteRewardFormik.errors
														.lotto6
												)
											}
											helperText={
												borletteRewardFormik.touched
													.lotto6 &&
												borletteRewardFormik.errors
													.lotto6
											}
										/>
									</Grid>
								</Grid>

								<Box py={2}>
									<Grid
										container
										spacing={2}
										justify="flex-end"
									>
										<Grid item xs={2}>
											<Button
												className={
													classes.fixPositioned
												}
												pending={pendingUpdateRewards}
												type="submit"
												color="primary"
												variant="contained"
												fullWidth
											>
												Update
											</Button>
										</Grid>
									</Grid>
								</Box>
							</Box>
						</form>
					</Card>
				</Grid>
				<Grid item xs={12} md={12}>
					<form
						noValidate
						autoComplete="off"
						onSubmit={borletteMajorRestrictionFormik.handleSubmit}
					>
						<Card
							title="Major Restriction"
							fontIcon="fa-info-circle"
						>
							<Box className={classes.container}>
								<Grid container spacing={2}>
									<Grid item xs={6} sm={4}>
										<TextField
											type="text"
											label="Borlette"
											name="borlette"
											value={
												borletteMajorRestrictionFormik
													.values.borlette
											}
											onChange={
												borletteMajorRestrictionFormik.handleChange
											}
										/>
									</Grid>
									<Grid item xs={6} sm={4}>
										<TextField
											type="text"
											label="Marriage"
											name="marriage"
											value={
												borletteMajorRestrictionFormik
													.values.marriage
											}
											onChange={
												borletteMajorRestrictionFormik.handleChange
											}
										/>
									</Grid>
									<Grid item xs={6} sm={4}>
										<TextField
											type="text"
											label="Lotto 3"
											name="lotto3"
											value={
												borletteMajorRestrictionFormik
													.values.lotto3
											}
											onChange={
												borletteMajorRestrictionFormik.handleChange
											}
										/>
									</Grid>
								</Grid>
								<Grid container spacing={2}>
									<Grid item xs={6} sm={4}>
										<TextField
											type="text"
											label="Lotto 4"
											name="lotto4"
											value={
												borletteMajorRestrictionFormik
													.values.lotto4
											}
											onChange={
												borletteMajorRestrictionFormik.handleChange
											}
										/>
									</Grid>
									<Grid item xs={6} sm={4}>
										<TextField
											type="text"
											label="Lotto 5"
											name="lotto5"
											value={
												borletteMajorRestrictionFormik
													.values.lotto5
											}
											onChange={
												borletteMajorRestrictionFormik.handleChange
											}
										/>
									</Grid>
									<Grid item xs={6} sm={4}>
										<TextField
											type="text"
											label="Lotto 6"
											name="lotto6"
											value={
												borletteMajorRestrictionFormik
													.values.lotto6
											}
											onChange={
												borletteMajorRestrictionFormik.handleChange
											}
										/>
									</Grid>
								</Grid>
								<Box py={2}>
									<Grid
										container
										spacing={2}
										justify="flex-end"
									>
										<Grid item xs={2}>
											<Button
												className={
													classes.fixPositioned
												}
												pending={
													pendingUpdateRestriction
												}
												type="submit"
												onClick={(e) => null}
												color="primary"
												variant="contained"
												fullWidth
											>
												Update
											</Button>
										</Grid>
									</Grid>
								</Box>
							</Box>
						</Card>
					</form>
				</Grid>
				<Grid item xs={12} md={12}>
					<form
						noValidate
						autoComplete="off"
						onSubmit={borletteCustomRestrictionFormik.handleSubmit}
					>
						<Card
							title="Custom Restriction"
							fontIcon="fa-info-circle"
						>
							<Box className={classes.container}>
								{borletteCustomRestrictionFormik.values
									.length === 0 ? (
									pendingUpdateRestrictionCustom ? (
										<Loader />
									) : (
										<NoData
											text="No Custom Restriction Added"
											actionTitle="Add Custom Restriction"
											onAction={addCustomRestrictions}
										/>
									)
								) : (
									<>
										{borletteCustomRestrictionFormik.values.map(
											(
												borletteCustomRestriction,
												index
											) => (
												<Grid
													container
													spacing={2}
													key={`custom-restriction-${index}`}
													justify="center"
													alignItems="center"
												>
													<Grid item xs>
														<TextField
															type="text"
															label="Number"
															name={`${index}.number`}
															value={
																borletteCustomRestrictionFormik
																	.values[
																	index
																].number
															}
															onChange={
																borletteCustomRestrictionFormik.handleChange
															}
														/>
													</Grid>
													<Grid item xs>
														<TextField
															type="text"
															label="Value"
															name={`${index}.value`}
															value={
																borletteCustomRestrictionFormik
																	.values[
																	index
																].value
															}
															onChange={
																borletteCustomRestrictionFormik.handleChange
															}
														/>
													</Grid>
													<Grid item xs={1}>
														<Icon
															onClick={() =>
																removeCustomRestrictions(
																	index
																)
															}
															className={clsx(
																"fa fa-2x",
																"fa-trash",
																classes.delete
															)}
														/>
													</Grid>
												</Grid>
											)
										)}
										<Box py={2}>
											<Grid
												container
												spacing={2}
												justify="flex-end"
											>
												<Grid item xs={4} sm={2}>
														<Button
															type="button"
														onClick={
															addCustomRestrictions
														}
														color="primary"
														variant="outlined"
														fullWidth={200}
													>
														Add More
													</Button>
												</Grid>
												<Grid item xs={3} sm={2}>
													<Button
														type="submit"
														pending={
															pendingUpdateRestrictionCustom
														}
														onClick={(e) => null}
														color="primary"
														variant="contained"
														fullWidth={200}
													>
														Save
													</Button>
												</Grid>
											</Grid>
										</Box>
									</>
								)}
							</Box>
						</Card>
					</form>
				</Grid>
			</Grid>
		</>
	);
};
