import React, { useState, useEffect } from "react";
import moment from "moment";
import {
	Drawer,
	Typography,
	Box,
	Icon,
	useTheme,
	useMediaQuery,
} from "@material-ui/core";
import Header from "~/modules/Header";
import InfoSummary from "~/modules/InfoSummary";
import SalesSummary from "~/modules/SalesSummary";
import DaterangePicker from "~/components/DaterangePicker";

import { getFranchiseSummary } from "~/redux/helpers/franchise";

import useStyles from "./styles";

export default function Dashboard() {
	const classes = useStyles();
	const [pending, setPending] = useState(true);
	const [salesSummary, setSalesSummary] = useState([]);
	const [graphData, setGraphData] = useState([0, 0]);
	const [infoSummary, setInfoSummary] = useState([
		{
			title: "Sales",
			value: null,
			icon: "fa-bar-chart",
		},
		{
			title: "Awards",
			value: null,
			icon: "fa-trophy",
		},
		{
			title: "Commission",
			value: null,
			icon: "fa-scissors",
		},
		{
			title: "Megapay Commission",
			value: null,
			icon: "fa-bank",
		},
		{
			title: "Net Profit",
			value: null,
			icon: "fa-bank",
		},
	]);
	const [dateRange, setDateRange] = useState({
		// startDate: moment().subtract(7, "days").valueOf(),
		startDate: moment().startOf("day").valueOf(),
		endDate: moment().endOf("day").valueOf(),
	});

	const fetchFranchiseData = (startDate, endDate) => {
		setPending(true);
		getFranchiseSummary(startDate, endDate).then(
			(response) => {
				let sales = 0,
					rewards = 0,
					commissions = 0,
					megaPayCommissions = 0;
				setGraphData([
					response.salesByBorletteType.morning,
					response.salesByBorletteType.evening,
				]);
				setSalesSummary(
					Object.values(response.transactionDataByAgent).map(
						(item) => {
							sales += item.sales;
							rewards += item.rewards;
							commissions += item.commissions;
							megaPayCommissions += item.megaPayCommissions;
							return {
								...item,
								agent: item.agent,
								sales: item.sales / 100,
								rewards: item.rewards / 100,
								commissions: item.commissions / 100,
								megaPayCommissions:
									item.megaPayCommissions / 100,
								balance:
									(item.sales -
										item.commissions -
										item.rewards) /
									100,
							};
						}
					)
				);
				setInfoSummary([
					{
						title: "Sales",
						value: `$ ${sales / 100}`,
						icon: "fa-bar-chart",
					},
					{
						title: "Awards",
						value: `$ ${rewards / 100}`,
						icon: "fa-trophy",
					},
					{
						title: "Commission",
						value: `$ ${commissions / 100}`,
						icon: "fa-scissors",
					},
					{
						title: "Megapay Commission",
						value: `$ ${megaPayCommissions / 100}`,
						icon: "fa-bank",
					},
					{
						title: "Net Profit",
						value: `$ ${(sales - rewards - commissions)/100}`,
						icon: "fa-bank",
					},
				]);
				setPending(false);
			}
		);
	};

	const handleDateRangeChange = (dateRange) => {
		setDateRange({
			startDate: dateRange[0],
			endDate: dateRange[1],
		});
		fetchFranchiseData(dateRange[0], dateRange[1]);
	};

	useEffect(() => {
		fetchFranchiseData(dateRange.startDate, dateRange.endDate);
	}, []);

	return (
		<>
			<Header />
			<Box className={classes.container}>
				<Box className={classes.pageTitleWrapper}>
					<Typography variant="h1">Dashboard</Typography>
					<DaterangePicker
						startDate={dateRange.startDate}
						endDate={dateRange.endDate}
						onChange={handleDateRangeChange}
					/>
				</Box>
				<Box className={classes.infoSummaryContainer}>
					<InfoSummary data={infoSummary} pending={pending} />
				</Box>
				<Box className={classes.salesSummaryContainer}>
					<SalesSummary
						graphData={graphData || []}
						data={salesSummary}
						pending={pending}
					/>
				</Box>
			</Box>
		</>
	);
}
