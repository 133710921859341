import React, { useState, useEffect } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { MenuItem, Grid } from "@material-ui/core";

import Button from "~/components/Forms/Button";
import TextField from "~/components/Forms/TextField";
import Table from "~/components/Table";

import { fetchBorletteTransaction } from "~/redux/helpers/transaction";

import useStyles from "./styles";

export default (props) => {
	const classes = useStyles();
	const { borletteId } = useParams();
	const [pending, setPending] = useState(true);
	const [transactions, setTransactions] = useState([]);
	const [filter, setFilter] = useState({
		transactionType: "ALL",
	});
	const getBorletteTransaction = () => {
		setPending(true);
		fetchBorletteTransaction(borletteId)
			.then((response) => {
				setTransactions(response.transactionData);
			})
			.catch((error) => console.error(error))
			.finally(() => setPending(false));
	};
	const columns = [
		{ id: "date", label: "Date", minWidth: 100 },
		{
			id: "transactionDescription",
			label: "Transaction Description",
			minWidth: 170,
		},
		{ id: "transactionAmount", label: "Amount", minWidth: 100 },
		{ id: "status", label: "Status", minWidth: 100 },
	];

	useEffect(() => {
		getBorletteTransaction();
	}, []);

	const getTransactionDescription = (
		transactionName,
		transactionAmount,
		user
	) => {
		switch (transactionName) {
			case "BORLETTE_SALE":
				return `Agent "${user}" made a sale of ${transactionAmount}`;
			case "BORLETTE_COMMISSION":
				return `Agent "${user}" received a commission of ${transactionAmount}`;
			case "BORLETTE_PAYMENT_RECEIVED":
				return `Recevied payment of ${transactionAmount} from agent "${user}"`;
			case "BORLETTE_PAYMENT_MADE":
				return `Made payment of ${transactionAmount} to agent "${user}"`;
			case "BORLETTE_MEGAPAY_COMMISSION":
				return `Megapay Commission of ${transactionAmount} from agent "${user}"`;
			default:
				return "";
		}
	};

	const rows = transactions
		.map((transaction) => ({
			...transaction,
			transactionAmount: transaction.transactionAmount / 100,
			date: moment(transaction.createdAt).format("DD MMM, YYYY"),
			transactionDescription: getTransactionDescription(
				transaction.transactionName,
				transaction.transactionAmount/100,
				transaction.name
			),
		}))
		.filter((transaction) =>
			Boolean(filter.transactionType) && filter.transactionType !== "ALL"
				? transaction.transactionName === filter.transactionType
				: true
		);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid item xs={3}>
						<TextField
							select
							type="select"
							label="Transaction Type"
							value={filter.transactionType}
							onChange={(e) =>
								setFilter({
									...filter,
									transactionType: e.target.value,
								})
							}
						>
							<MenuItem value={"ALL"}>All Transaction</MenuItem>
							<MenuItem value={"BORLETTE_SALE"}>
								Borlette Ticket Sale
							</MenuItem>
							<MenuItem value={"BORLETTE_COMMISSION"}>
								Borlette Ticket Commission
							</MenuItem>
							<MenuItem value={"BORLETTE_REWARDS"}>
								Borlette Rewards
							</MenuItem>
							<MenuItem value={"BORLETTE_PAYMENT_MADE"}>
								Payment Made to Agent
							</MenuItem>
							<MenuItem value={"BORLETTE_PAYMENT_RECEIVED"}>
								Payment Received from Agent
							</MenuItem>
						</TextField>
					</Grid>
					<Grid item xs={3}>
						<Button
							onClick={(e) => null}
							color="primary"
							variant="contained"
							className={classes.filterButton}
						>
							Filter
						</Button>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Table
					stickyHeader={true}
					columns={columns}
					rows={rows}
					pending={pending}
				/>
			</Grid>
		</Grid>
	);
};
