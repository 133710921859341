import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chart from "chart.js";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import clsx from "clsx";
import {
	Paper,
	Typography,
	Box,
	Icon,
	useTheme,
	useMediaQuery,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "1rem",
	},
	value: {
		lineHeight: "1.5",
	},
}));

export default function Graph(props) {
	const { id, type, data, options } = props;
	const canvasEl = useRef(null);

	useEffect(() => {
		configureChart();
	}, []);


	const configureChart = () => {
		const chartCanvas = canvasEl.current;
		new Chart(chartCanvas, {
			type: type,
			data: data,
			options: options,
		});
	};

	return <canvas id={id} ref={canvasEl} />;
}
