import React from "react";
import {
	makeStyles,
	TextField as MUITextField,
	Tooltip,
	InputAdornment,
} from "@material-ui/core";
import Select from "react-virtualized-select";

import "react-select/dist/react-select.css";
import "react-virtualized-select/styles.css";


const useStyles = makeStyles((theme) => ({
	root: {
		//height: "70px",
	},
	textField: {},

	cssLabel: {
		color: theme.palette.primary.main,
	},
	cssDisabled: {
		"&::before": {
			borderBottomStyle: "solid",
		},
	},
	cssFilledInput: {
		"&::before": {
			borderBottom: `1px solid ${theme.palette.primary.light}`,
		},
		"&$cssDisabled": {
			"&::before": {
				borderBottom: `1px solid rgba(0,0,0,0.42)`,
			},
		},
		"&$cssFocused": {
			//borderColor: `${theme.palette.primary.main} !important`,
		},
	},

	cssFocused: {},
}));

export default (props) => {
	const {
		id,
		name,
		options,
		...restProps
	} = props;

	const classes = useStyles();

	// const info = tooltipProps && (
	// 	<Tooltip title={tooltipProps.title} arrow placement="right">
	// 		{tooltipProps.icon || <InfoOutlinedIcon />}
	// 	</Tooltip>
	// );

	return (
		<div className={classes.root}>
			<Select options={options} {...restProps} />
		</div>
	);
};
