import React, { useState } from "react";
import clsx from "clsx";
import { Box, Grid, MenuItem, Typography, Icon } from "@material-ui/core";

import Table from "~/components/Table";
import SidePanel from "~/components/SidePanel";
import Button from "~/components/Forms/Button";
import NoData from "~/components/NoData";
import TextField from "~/components/Forms/TextField";

import useStyles from "./styles";

const capitalize = (s) => {
	if (typeof s !== "string") return "";
	return s.charAt(0).toUpperCase() + s.slice(1);
};

export default function ({ previewResultData, publishProgress, isOpen, onClose, onPublish, results }) {
	let totalSales = 0, totalRewards = 0;
	const classes = useStyles();
	const columns = [
		{ id: "combination", label: "Combination" },
		{ id: "number", label: "Number" },
		{ id: "sales", label: "Sales" },
		{ id: "rewards", label: "Rewards" },
	];
	const rows = [];
	for (let key in previewResultData) {
		totalSales += previewResultData[key].amountReceived;
		totalRewards += previewResultData[key].amountWon;
		const combination = ["first", "second", "third"].includes(
			previewResultData[key].combination
		)
			? `Borlette ${previewResultData[key].combination}`
			: previewResultData[key].combination;
		rows.push({
			number: key,
			sales: previewResultData[key].amountReceived/100,
			rewards: (previewResultData[key].amountWon || 0)/100,
			combination: capitalize(combination),
		});
	}

	const handleLotteryPublish = () => {
		onPublish(results);
	}

	const renderTotal = () => (
		<Box className={classes.tableFooter}>
			<Grid container spacing={4} justify="flex-end">
				<Grid item xs={4}>
					<Typography variant="h6">Total Sales</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="h6">{totalSales/100}</Typography>
				</Grid>
			</Grid>
			<Grid container spacing={4} justify="flex-end">
				<Grid item xs={4}>
					<Typography variant="h6">Total Rewards</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography variant="h6">{(totalRewards || 0)/100}</Typography>
				</Grid>
			</Grid>
		</Box>
	);
	return (
		<SidePanel
			title={"Preview Lottery Results"}
			isOpen={isOpen}
			onClose={onClose}
			onAction={(e) => null}
		>
			<Box className={classes.contentContainer}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Table
							columns={columns}
							rows={rows}
							footer={renderTotal()}
						/>
					</Grid>
				</Grid>

				<Box className={classes.buttonContainer}>
					<Button
						onClick={onClose}
						color="primary"
						variant="outlined"
						fullWidth
					>
						Cancel
					</Button>
					<Button
						onClick={handleLotteryPublish}
						pending={publishProgress}
						color="primary"
						variant="contained"
						fullWidth
					>
						Publish Result
					</Button>
				</Box>
			</Box>
		</SidePanel>
	);
}
