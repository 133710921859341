import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import { Box, Snackbar } from "@material-ui/core";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import clsx from "clsx";

import {
	toggleSidebar,
	updateError,
	updateSuccessMessage,
} from "~/redux/actions/appConfig";
import { userInfo } from "~/redux/actions/user";

import Sidebar from "~/modules/Sidebar";
import Login from "~/views/Login";
import Dashboard from "~/views/Dashboard";
import Lotteries from "~/views/Lotteries";
import LotteryDetails from "~/views/LotteryDetails";
import Agents from "~/views/Agents";
import AgentDetails from "~/views/AgentDetails";
import Configuration from "~/views/Configuration";
import Transactions from "~/views/Transactions";

import Plans from "~/views/Plans";
import './App.css'

const Alert = (props) => {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const themeConfig = (theme) => {
	return {
		palette: {
			...theme.palette,
		},
		typography: {
			...theme.typography,
		},
		overrides: {
			MuiInputBase: {
				root: {
					fontSize: `${theme.typography.baseFontSize}rem`,
				},
			},
			MuiTextField: {
				root: {
					"&:focus": {
						outline: "none",
					},
				},
			},
			MuiOutlinedInput: {
				root: {
					"&$focused $notchedOutline": {
						borderWidth: 1,
					},
				},
				input: {
					padding: "1rem 1rem",
				},
			},
			MuiIcon: {
				root: {
					fontSize: '1.25rem'
				}
			},
			// MuiSelect: {
			// 	outlined: {
			// 		padding: ".5rem 1rem",
			// 		fontSize: "0.875rem",
			// 	},
			// },
			// MuiButton: {
			// 	root: {
			// 		borderRadius: 0,
			// 		padding: ".5rem 2rem",
			// 	},
			// 	label: {
			// 		fontSize: `${theme.typography.baseFontSize}rem`,
			// 	},
			// },
			// MuiDialog: {
			// 	paperScrollPaper: {
			// 		borderRadius: 0,
			// 	},
			// },
			MuiPaper: {
				elevation1: {
					// boxShadow: "-3px 2px 2px 0 rgba(119,151,178,0.17)"
					boxShadow: "0 0 10px 0 rgba(51,60,75,0.1);",
				},
			},
			// MuiDialogTitle: {
			// 	root: {
			// 		padding: 0,
			// 	},
			// },
			MuiListItem: {
				root: {
					paddingTop: "0.563rem",
					paddingBottom: "0.438rem",
				},
				button: {
					borderRadius: 5,
				},
				gutters: {
					paddingLeft: "0.75rem",
					paddingRight: "0.75rem",
				},
			},
			MuiListItemIcon: {
				root: {
					minWidth: "3.125rem",
				},
			},
			MuiSvgIcon: {
				root: {
					cursor: "pointer",
				},
			},
			PrivateNotchedOutline: {
				root: {
					borderRadius: 5,
				},
			},
		},
	};
};

export default function App() {
	const appConfig = useSelector((state) => state.appConfig);
	const { isLoggedIn } = useSelector((state) => state.user);
	const dispatch = useDispatch();
	const location = useLocation();

	const handleCloseErrorMessage = () => {
		dispatch(updateError(null))
	}

	const handleCloseSuccessMessage = () => {
		dispatch(updateSuccessMessage(null));
	}

	useEffect(() => {
		dispatch(userInfo());
	}, [])

	useEffect(() => {
		dispatch(toggleSidebar(false));
	}, [location]);

	if (isLoggedIn === null) {
		return null
	}

	return (
		<MuiThemeProvider
			theme={createMuiTheme(
				appConfig.layout ? themeConfig(appConfig.layout.theme) : {}
			)}
		>
			{isLoggedIn ? (
				<>
					<Sidebar navigation={appConfig.navigation.sidebar} />
					<Box
						className={clsx(
							"hasSidebar",
							appConfig.sidebarDisplayed ? "sidebarDisplayed" : ""
						)}
					>
						<Switch>
							<Route path="/dashboard">
								<Dashboard />
							</Route>
							<Route path="/lotteries">
								<Lotteries />
							</Route>
							<Route path="/lottery/:borletteId">
								<LotteryDetails />
							</Route>
							<Route path="/agents">
								<Agents />
							</Route>
							<Route path="/agent/:agentId">
								<AgentDetails />
							</Route>
							<Route path="/transactions">
								<Transactions />
							</Route>
							<Route path="/configuration/lottery">
								<Configuration />
							</Route>
							<Route path="/configuration/agent">
								<Configuration />
							</Route>
							<Route path="/plans/:type">
								<Plans />
							</Route>
							<Route path="*">
								<Redirect to="/dashboard" />
							</Route>
						</Switch>
					</Box>
				</>
			) : (
				<Switch>
					<Route exact path="/">
						<Login />
					</Route>
					<Route path="*">
						<Redirect to="/" />
					</Route>
				</Switch>
			)}
			<Snackbar
				open={Boolean(appConfig.errorMessage)}
				autoHideDuration={6000}
				onClose={handleCloseErrorMessage}
			>
				<Alert onClose={handleCloseErrorMessage} severity="error">
					{appConfig.errorMessage}
				</Alert>
			</Snackbar>
			<Snackbar
				open={Boolean(appConfig.successMessage)}
				autoHideDuration={6000}
				onClose={handleCloseSuccessMessage}
			>
				<Alert onClose={handleCloseSuccessMessage} severity="success">
					{appConfig.successMessage}
				</Alert>
			</Snackbar>
		</MuiThemeProvider>
	);
}