import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
	Paper,
	Typography,
	Box,
	Icon,
	useTheme,
	useMediaQuery,
	CircularProgress,
} from "@material-ui/core";
import Loader from "~/components/Loader";


const useStyles = makeStyles((theme) => ({
	container: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "1rem",
	},
	value: {
		lineHeight: "1.5"
	},
}));

export default function InfoCard(props) {
	const { data: { title, value, icon }, colorScheme, pending } = props;
	const classes = useStyles();

	return (
		<Paper
			className={classes.container}
			style={{
				backgroundColor: colorScheme.background,
				color: colorScheme.contrast,
			}}
		>
			<Box className={classes.info}>
				{pending ? (
					<CircularProgress size={20} />
				) : (
					<>
						<Typography variant="h1" className={classes.value}>
							{value}
						</Typography>
						<Typography variant="caption" className={classes.value}>
							{title}
						</Typography>
					</>
				)}
			</Box>
			<Box className={classes.icon}>
				<Icon className={clsx("fa fa-2x", icon)} />
			</Box>
		</Paper>
	);
}
