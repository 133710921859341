const initialState = {
	isLoggedIn: null,
	user: {},
	error: null
};
export default function appConfig(state = initialState, action = {}) {
	switch (action.type) {
		case "LOGIN_SUCCESS":
			return {
				...state,
				isLoggedIn: true,
				user: action.payload,
			};
		case "LOGIN_FAILED":
			return {
				...state,
				isLoggedIn: false,
				error: action.payload,
				user: {},
			};
		case "LOGOUT_SUCCESS":
			return {
				...state,
				isLoggedIn: false,
				error: null,
				user: {},
			};
		default:
			return {
				...state,
			};
	}
}
