import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	container: {
		padding: "1rem",
	},
	borderRight: {
		borderRight: `1px solid ${theme.palette.background.default}`,
	},
	sectionHeader: {
		paddingBottom: "1rem",
	},
	fullHeight: {
		height: "100%",
	},
	filterButton: {
		padding: "1rem 1.75rem",
		borderRadius: "0.25rem"
	},
}));
