import React, { useState, useRef } from "react";
import clsx from "clsx";
import {
	Box,
	Grid,
	MenuItem,
	Typography,
	CircularProgress,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";

import SidePanel from "~/components/SidePanel";
import Button from "~/components/Forms/Button";
import TextField from "~/components/Forms/TextField";

import useStyles from "./styles";

const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];
const years = [];
for (let i = 2010; i > 1970; i--) {
	years.push(i);
}

export default function (props) {
	const {
		isOpen,
		onClose,
		agent,
		onAction,
		onBlockUnblock,
		pending,
		actionProgress,
	} = props;
	const classes = useStyles();
	const validationSchema = useRef(
		yup.object({
			firstName: yup.string().required("First name is required"),
			lastName: yup.string().required("Last name is required"),
			email: yup.string().required("Email is required"),
			phoneNumber: yup.string().required("Phone number is required"),
			password: yup
				.string()
				.when("isNew", {
					is: true,
					then: yup.string().required("Password is required"),
				})
				.when("isNew", {
					is: false,
					then: yup.string().nullable(),
				}),
			address: yup.object({
				address1: yup.string().required("Address is required"),
				state: yup.string().required("State is required"),
				city: yup.string().required("City is required"),
				zipcode: yup.string().required("Zipcode is required"),
			}),
			dob: yup.object({
				date: yup.string().required("Date is required"),
				month: yup.string().required("Month is required"),
				year: yup.string().required("Year is required"),
			}),
			bankAccount: yup.array().of(
				yup.object({
					accountNumber: yup
						.string()
						.required("Account NUmber is required"),
					bankName: yup.string().required("Bank Name is required"),
					ifscCode: yup.string().required("IFSC code is required"),
					simNif: yup.string(),
				})
			),
		})
	);
	const formik = useFormik({
		initialValues: agent
			? Object.assign(
					{
						firstName: "",
						lastName: "",
						email: "",
						phoneNumber: "",
						password: "",
						dob: {
							date: "",
							month: "",
							year: "",
						},
						address: {
							address1: "",
							address2: "",
							state: "",
							city: "",
							zipcode: "",
						},
						bankAccount: [
							{
								accountNumber: "",
								bankName: "",
								ifscCode: "",
								simNif: "",
							},
						],
						commission: "",
					},
					agent
			  )
			: {
					firstName: "",
					lastName: "",
					email: "",
					phoneNumber: "",
					password: "",
					dob: {
						date: "",
						month: "",
						year: "",
					},
					address: {
						address1: "",
						address2: "",
						state: "",
						city: "",
						zipcode: "",
					},
					bankAccount: [
						{
							accountNumber: "",
							bankName: "",
							ifscCode: "",
							simNif: "",
						},
					],
					commission: "",
					isNew: true,
			  },
		validationSchema: validationSchema.current,
		onSubmit: onAction,
	});

	return (
		<SidePanel
			title={agent ? "Update Agent Details" : "Add New Agent"}
			isOpen={isOpen}
			onClose={onClose}
			showAction={Boolean(agent)}
			actionTitle={
				agent && agent.status === "ACTIVE" ? "Block" : "Unblock"
			}
			actionType="danger"
			actionIcon="fa-ban"
			onAction={onBlockUnblock}
			actionProgress={actionProgress}
		>
			<form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
				<Box className={classes.contentContainer}>
					<Typography variant="h4" className={classes.sectionHeader}>
						Basic Details
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<TextField
								type="text"
								label="First Name"
								name="firstName"
								value={formik.values.firstName}
								onChange={formik.handleChange}
								error={
									formik.touched.firstName &&
									Boolean(formik.errors.firstName)
								}
								helperText={
									formik.touched.firstName &&
									formik.errors.firstName
								}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								type="text"
								label="Last Name"
								name="lastName"
								value={formik.values.lastName}
								onChange={formik.handleChange}
								error={
									formik.touched.lastName &&
									Boolean(formik.errors.lastName)
								}
								helperText={
									formik.touched.lastName &&
									formik.errors.lastName
								}
							/>
						</Grid>
					</Grid>
					<TextField
						type="text"
						label="Email"
						name="email"
						value={formik.values.email}
						onChange={formik.handleChange}
						error={
							formik.touched.email && Boolean(formik.errors.email)
						}
						helperText={formik.touched.email && formik.errors.email}
					/>
					<TextField
						type="text"
						label="Phone Number"
						name="phoneNumber"
						value={formik.values.phoneNumber}
						onChange={formik.handleChange}
						error={
							formik.touched.phoneNumber &&
							Boolean(formik.errors.phoneNumber)
						}
						helperText={
							formik.touched.phoneNumber &&
							formik.errors.phoneNumber
						}
					/>
					<TextField
						type="password"
						label="Password"
						name="password"
						value={formik.values.password}
						onChange={formik.handleChange}
						error={
							formik.touched.password &&
							Boolean(formik.errors.password)
						}
						helperText={
							formik.touched.password &&
							formik.errors.password
						}
					/>
					<Grid container spacing={2}>
						<Grid item xs={4}>
							<TextField
								type="text"
								label="Birth Date"
								name="dob.date"
								value={formik.values.dob.date}
								onChange={formik.handleChange}
								error={
									formik.touched.dob &&
									formik.touched.dob.date &&
									Boolean(
										formik.errors.dob &&
											formik.errors.dob.date
									)
								}
								helperText={
									formik.touched.dob &&
									formik.touched.dob.date &&
									formik.errors.dob &&
									formik.errors.dob.date
								}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								select
								type="select"
								label="Month"
								name="dob.month"
								value={formik.values.dob.month}
								onChange={formik.handleChange}
								error={
									formik.touched.dob &&
									formik.touched.dob.month &&
									Boolean(
										formik.errors.dob &&
											formik.errors.dob.month
									)
								}
								helperText={
									formik.touched.dob &&
									formik.touched.dob.month &&
									formik.errors.dob &&
									formik.errors.dob.month
								}
							>
								{months.map((month, index) => (
									<MenuItem
										key={`${month.toLowerCase()}`}
										value={index + 1}
									>
										{month}
									</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid item xs={4}>
							<TextField
								select
								type="select"
								label="Year"
								name="dob.year"
								value={formik.values.dob.year}
								onChange={formik.handleChange}
								error={
									formik.touched.dob &&
									formik.touched.dob.year &&
									Boolean(
										formik.errors.dob &&
											formik.errors.dob.year
									)
								}
								helperText={
									formik.touched.dob &&
									formik.touched.dob.year &&
									formik.errors.dob &&
									formik.errors.dob.year
								}
							>
								{years.map((year, index) => (
									<MenuItem key={`${year}`} value={year}>
										{year}
									</MenuItem>
								))}
							</TextField>
						</Grid>
					</Grid>

					<Typography variant="h4" className={classes.sectionHeader}>
						Address
					</Typography>
					<TextField
						type="text"
						label="Address Line 1"
						name="address.address1"
						value={formik.values.address.address1}
						onChange={formik.handleChange}
						error={
							formik.touched.address &&
							formik.touched.address.address1 &&
							Boolean(
								formik.errors.address &&
									formik.errors.address.address1
							)
						}
						helperText={
							formik.touched.address &&
							formik.touched.address.address1 &&
							formik.errors.address &&
							formik.errors.address.address1
						}
					/>
					<TextField
						type="text"
						label="Address Line 2"
						name="address.address2"
						value={formik.values.address.address2}
						onChange={formik.handleChange}
						error={
							formik.touched.address &&
							formik.touched.address.address2 &&
							Boolean(
								formik.errors.address &&
									formik.errors.address.address2
							)
						}
						helperText={
							formik.touched.address &&
							formik.touched.address.address2 &&
							formik.errors.address &&
							formik.errors.address.address2
						}
					/>
					<Grid container spacing={2}>
						<Grid item xs={2} sm={4}>
							<TextField
								type="text"
								label="State"
								name="address.state"
								value={formik.values.address.state}
								onChange={formik.handleChange}
								error={
									formik.touched.address &&
									formik.touched.address.state &&
									Boolean(
										formik.errors.address &&
											formik.errors.address.state
									)
								}
								helperText={
									formik.touched.address &&
									formik.touched.address.state &&
									formik.errors.address &&
									formik.errors.address.state
								}
							/>
						</Grid>
						<Grid item xs={2} sm={4}>
							<TextField
								type="text"
								label="City"
								name="address.city"
								value={formik.values.address.city}
								onChange={formik.handleChange}
								error={
									formik.touched.address &&
									formik.touched.address.city &&
									Boolean(
										formik.errors.address &&
											formik.errors.address.city
									)
								}
								helperText={
									formik.touched.address &&
									formik.touched.address.city &&
									formik.errors.address &&
									formik.errors.address.city
								}
							/>
						</Grid>
						<Grid item xs={2} sm={4}>
							<TextField
								type="text"
								label="ZipCode"
								name="address.zipcode"
								value={formik.values.address.zipcode}
								onChange={formik.handleChange}
								error={
									formik.touched.address &&
									formik.touched.address.zipcode &&
									Boolean(
										formik.errors.address &&
											formik.errors.address.zipcode
									)
								}
								helperText={
									formik.touched.address &&
									formik.touched.address.zipcode &&
									formik.errors.address &&
									formik.errors.address.zipcode
								}
							/>
						</Grid>
					</Grid>

					<Typography variant="h4" className={classes.sectionHeader}>
						Bank Account Details
					</Typography>
					{formik.values.bankAccount.map((account, index) => (
						<>
							<TextField
								type="text"
								label="Account Number"
								name={`bankAccount.${index}.accountNumber`}
								value={
									formik.values.bankAccount[index]
										.accountNumber
								}
								onChange={formik.handleChange}
								error={
									formik.touched.bankAccount &&
									formik.touched.bankAccount.length &&
									formik.touched.bankAccount[index]
										.accountNumber &&
									Boolean(
										formik.errors.bankAccount &&
											formik.errors.bankAccount[index]
												.accountNumber
									)
								}
								helperText={
									formik.touched.bankAccount &&
									formik.touched.bankAccount.length &&
									formik.touched.bankAccount[index]
										.accountNumber &&
									formik.errors.bankAccount &&
									formik.errors.bankAccount[index]
										.accountNumber
								}
							/>
							<TextField
								type="text"
								label="Bank Name"
								name={`bankAccount.${index}.bankName`}
								value={
									formik.values.bankAccount[index].bankName
								}
								onChange={formik.handleChange}
								error={
									formik.touched.bankAccount &&
									formik.touched.bankAccount.length &&
									formik.touched.bankAccount[index]
										.bankName &&
									Boolean(
										formik.errors.bankAccount &&
											formik.errors.bankAccount[index]
												.bankName
									)
								}
								helperText={
									formik.touched.bankAccount &&
									formik.touched.bankAccount.length &&
									formik.touched.bankAccount[index]
										.bankName &&
									formik.errors.bankAccount &&
									formik.errors.bankAccount[index].bankName
								}
							/>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<TextField
										type="text"
										label="IFSC Code"
										name={`bankAccount.${index}.ifscCode`}
										value={
											formik.values.bankAccount[index]
												.ifscCode
										}
										onChange={formik.handleChange}
										error={
											formik.touched.bankAccount &&
											formik.touched.bankAccount.length &&
											formik.touched.bankAccount[index]
												.ifscCode &&
											Boolean(
												formik.errors.bankAccount &&
													formik.errors.bankAccount[
														index
													].ifscCode
											)
										}
										helperText={
											formik.touched.bankAccount &&
											formik.touched.bankAccount.length &&
											formik.touched.bankAccount[index]
												.ifscCode &&
											formik.errors.bankAccount &&
											formik.errors.bankAccount[index]
												.ifscCode
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										type="text"
										label="SIM/NIF"
										name={`bankAccount.${index}.simNif`}
										value={
											formik.values.bankAccount[index]
												.simNif
										}
										onChange={formik.handleChange}
										error={
											formik.touched.bankAccount &&
											formik.touched.bankAccount.length &&
											formik.touched.bankAccount[index]
												.simNif &&
											Boolean(
												formik.errors.bankAccount &&
													formik.errors.bankAccount[
														index
													].simNif
											)
										}
										helperText={
											formik.touched.bankAccount &&
											formik.touched.bankAccount.length &&
											formik.touched.bankAccount[index]
												.simNif &&
											formik.errors.bankAccount &&
											formik.errors.bankAccount[index]
												.simNif
										}
									/>
								</Grid>
							</Grid>
						</>
					))}

					<Typography variant="h4" className={classes.sectionHeader}>
						Commission Details
					</Typography>
					<TextField
						type="text"
						label="Borlette Commission"
						name="commission"
						value={formik.values.commission}
						onChange={formik.handleChange}
						error={
							formik.touched.commissions &&
							Boolean(formik.errors.commission)
						}
						helperText={
							formik.touched.commission &&
							formik.errors.commission &&
							formik.errors.commission
						}
					/>

					<Box className={classes.buttonContainer}>
						<Button
							onClick={onClose}
							color="primary"
							variant="outlined"
							disabled={pending}
							fullWidth
						>
							Cancel
						</Button>
						<Button
							type="submit"
							color="primary"
							variant="contained"
							disabled={pending}
							pending={pending}
							fullWidth
						>
							{Boolean(agent) ? "Update Agent" : "Add Agent"}
						</Button>
					</Box>
				</Box>
			</form>
		</SidePanel>
	);
}
