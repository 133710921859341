import React, { useState, useEffect } from "react";
import {
	Route,
	Switch,
	useHistory,
	useRouteMatch,
	matchPath,
} from "react-router-dom";
import clsx from "clsx";
import { Grid, Box, Typography, Paper } from "@material-ui/core";

import Header from "~/modules/Header";
import PageTitle from "~/modules/PageTitle";
import LotteryConfiguration from "~/modules/LotteryConfiguration";
import AgentConfiguration from "~/modules/AgentConfiguration";
import useStyles from "./styles";

export default function (props) {
	const classes = useStyles();
	const history = useHistory();
	const [activeIndex, setActiveIndex] = useState(0);
	const configurationTabs = [
		{
			title: "Borlette",
			path: "/configuration/lottery",
			url: "/configuration/lottery",
			isActive: useRouteMatch(`/configuration/lottery`),
		},
		{
			title: "Agents",
			path: "/configuration/agent",
			url: "/configuration/agent",
			isActive: useRouteMatch(`/configuration/agent`),
		}
	];

	useEffect(() => {
		setActiveIndex(
			configurationTabs.findIndex((item) =>
				matchPath(history.location.pathname, {
					path: item.path,
					exact: true,
					strict: false,
				})
			)
		);
	}, [history.location.pathname]);
	return (
		<>
			<Header />
			<PageTitle
				title="Configuration"
				showAction={false}
				tabs={configurationTabs.map((item) => item.title)}
				activeTabIndex={activeIndex}
				onTabChange={(e, index) => {
					history.push(configurationTabs[index].url);
				}}
			/>
			<Box className={classes.contentWrapper}>
				<Switch>
					<Route path="/configuration/lottery" exact={true}>
						<LotteryConfiguration />
					</Route>
					<Route path="/configuration/agent" exact={true}>
						<AgentConfiguration />
					</Route>
				</Switch>
			</Box>
		</>
	);
}
