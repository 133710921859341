import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	CircularProgress,
	Typography,
	Box,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	stickyHeaderContainer: {
		maxHeight: 600,
	},
	centeredData: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	noData: {
		color: theme.palette.text.disabled,
	},
	tableHeader: {
		...theme.typography.body2,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		padding: 10,
	},
	tableRow: {
		cursor: "pointer",
	},
	tableBody: {
		padding: "15px 10px",
		// textAlign: "center",
	},
}));

export default function ({
	columns = [],
	rows = [],
	onRowClick,
	stickyHeader,
	pagination,
	count,
	page,
	rowsPerPage,
	handleChangePage,
	footer,
	pending,
}) {
	const classes = useStyles();
	// const [page, setPage] = React.useState(0);
	// const [rowsPerPage, setRowsPerPage] = React.useState(10);

	// const handleChangePage = (event, newPage) => {
	// 	setPage(newPage);
	// };

	// const handleChangeRowsPerPage = (event) => {
	// 	setRowsPerPage(+event.target.value);
	// 	setPage(0);
	// };

	return (
		<Paper className={classes.root}>
			<TableContainer
				className={
					stickyHeader
						? classes.stickyHeaderContainer
						: classes.container
				}
			>
				<Table aria-label="sticky table" stickyHeader={!!stickyHeader}>
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									key={column.id}
									align={column.align}
									style={{ minWidth: column.minWidth }}
									classes={{ head: classes.tableHeader }}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{pending ? (
							<TableRow>
								<TableCell colSpan={columns.length}>
									<Box
										className={classes.centeredData}
										py={3}
									>
										<CircularProgress />
									</Box>
								</TableCell>
							</TableRow>
						) : rows.length === 0 ? (
							<TableRow>
								<TableCell colSpan={columns.length}>
									<Box
										className={classes.centeredData}
										py={4}
									>
										<Typography
											variant="h2"
											className={classes.noData}
										>
											No records found
										</Typography>
									</Box>
								</TableCell>
							</TableRow>
						) : (
							rows.map((row, index) => (
								<TableRow
									hover
									role="checkbox"
									tabIndex={-1}
									key={`table-row-${index}`}
									onClick={() =>
										onRowClick ? onRowClick(row) : null
									}
									classes={{ root: classes.tableRow }}
								>
									{columns.map((column) => {
										const value = row[column.id];
										return (
											<TableCell
												key={column.id}
												align={column.align}
												classes={{
													body: classes.tableBody,
												}}
											>
												{column.format &&
												typeof value === "number"
													? column.format(value)
													: value}
											</TableCell>
										);
									})}
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
			</TableContainer>
			{footer ? footer : ""}
			{pagination && (
				<TablePagination
					// rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={count}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					// onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			)}
		</Paper>
	);
}
