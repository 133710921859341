import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	contentWrapper: {
		padding: "0.5rem",
	},
	dialog: {
		width: "500px"
	},
}));
