import axios from "axios";
import config from "~/config";
import { getAccessToken } from "~/redux/helpers";

export const fetchTransactions = async ({
	startDate,
	endDate,
	limit,
	offset,
	transactionName,
}) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/transaction`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
			params: {
				startDate,
				endDate,
				limit,
				offset,
				transactionName,
			},
		});
		return await response.data;
	} catch (error) {
		return {
			error: error.response.data,
		};
	}
};

export const fetchBorletteTransactionSummary = async (borletteId) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/transaction/summary/borlette/${borletteId}`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
		});
		return await response.data;
	} catch (error) {
		return {
			error: error.response.data,
		};
	}
};

export const fetchAgentTransactionSummary = async (
	agentId,
	startDate,
	endDate
) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/transaction/summary/agent/${agentId}`,
			method: "GET",
			params: {
				startDate,
				endDate,
			},
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
		});
		return await response.data;
	} catch (error) {
		return {
			error: error.response.data,
		};
	}
};

export const fetchBorletteTransaction = async (borletteId) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/transaction/borlette/${borletteId}`,
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
		});
		return await response.data;
	} catch (error) {
		return {
			error: error.response.data,
		};
	}
};

export const updateBorlettePayment = async (
	borletteId,
	agentId,
	transactionType,
	transactionAmount
) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/transaction/payment/borlette/${borletteId}/agent/${agentId}`,
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
			data: JSON.stringify({
				transactionType,
				transactionAmount: transactionAmount,
			}),
		});
		return await response.data;
	} catch (error) {
		return {
			error: error.response.data,
		};
	}
};

export const updatePaymentForAgent = async (
	agentId,
	startDate,
	endDate,
	transactionType,
	transactionAmount
) => {
	try {
		const accessToken = await getAccessToken();
		const response = await axios({
			url: `${config.apiBase}/transaction/payment/agent/${agentId}`,
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken,
			},
			params: {
				startDate,
				endDate,
			},
			data: JSON.stringify({
				transactionType,
				transactionAmount: transactionAmount,
			}),
		});
		return await response.data;
	} catch (error) {
		return {
			error: error.response.data,
		};
	}
};