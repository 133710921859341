import React from "react";
import clsx from "clsx";
import { Box, Typography, Grid } from "@material-ui/core";


import Button from "~/components/Forms/Button";
import Tabs from "~/components/Tabs";
import Tab from "~/components/Tab";
import useStyles from "./styles";

export default function PageTitle(props) {
	const {
		title,
		showAction,
		actionTitle,
		actionIcon,
		onAction,
		tabs,
		activeTabIndex,
		onTabChange,
		rightElement,
	} = props;
	const classes = useStyles();
	return (
		<Box className={classes.pageTitleWrapper}>
			<Grid
				container
				justify="space-between"
				alignItems="center"
				className={classes.titleWrapper}
			>
				<Grid item xs={12} sm={8}>
					<Box py={1}>
						<Typography variant="h2" className={classes.title}>
							{title}
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} sm={4}>
					{rightElement && rightElement}
				</Grid>
				{tabs && tabs.length > 0 && (
					<Grid item xs={12} sm={12}>
						<Tabs
							value={activeTabIndex}
							onChange={onTabChange}
							aria-label="page header tabs"
						>
							{tabs.map((tab, index) => (
								<Tab
									key={`tab-item-${index}`}
									id={`page-header-tab-${index}`}
									aria-controls={`page-header-tabpanel-${index}`}
									label={tab}
								/>
							))}
						</Tabs>
					</Grid>
				)}
			</Grid>
			{showAction && (
				<Button
					className={classes.fixPositioned}
					onClick={onAction}
					color="primary"
					variant="contained"
					rounded
				>
					{actionIcon ? actionIcon : null}
					{actionTitle}
				</Button>
			)}
		</Box>
	);
}