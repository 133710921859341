import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { MenuItem, Grid } from "@material-ui/core";
import useStyles from "./styles";

import Loader from "~/components/Loader";
import Table from "~/components/Table";
import Button from "~/components/Forms/Button";
import TextField from "~/components/Forms/TextField";

import { getBorletteNumberBalance } from "~/redux/helpers/borlette";

const getRandomAmount = (min, max) => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min) + min);
};

const combinations = [
	"Borlette",
	"Marriage",
	"Lotus 3",
	"Lotus 4",
	"Lotus 5",
	"Lotus 6",
];

export default () => {
	const { borletteId } = useParams();
	const classes = useStyles();
	const [pending, setPending] = useState(true)
	const [filter, setFilter] = useState({
		combination: '',
		number: ''
	})
	const [numberBalance, setNumberBalance] = useState([])
	const columns = [
		{ id: "combination", label: "Combination", minWidth: 170 },
		{ id: "number", label: "Number", minWidth: 170 },
		{ id: "sales", label: "Sales", minWidth: 100 },
		{ id: "balance", label: "Balance", minWidth: 100 },
	];

	useEffect(() => {
		getBorletteNumberBalance(borletteId)
			.then((response) => {
				setNumberBalance(
					Object.keys(response.numberBalance).map((number) => ({
						combination: response.numberBalance[number].type,
						number: number,
						sales: response.numberBalance[number].amountPlayed / 100,
						balance:
							response.numberBalance[number].maxAmount === -1
								? "No Limit"
								: (response.numberBalance[number].maxAmount -
									response.numberBalance[number].amountPlayed) / 100,
					}))
				);
			})
			.finally(() => setPending(false));
	}, []);

	if (pending) {
		return <Loader />;
	}

	const rows = numberBalance.filter(item => {
		if (filter.combination || filter.number) {
			if (
				item.number.toString().toLowerCase() ===
				filter.number.toString().toLowerCase()
			) {
				return true;
			}
			if (item.combination.toString().toLowerCase() === filter.combination.toString().toLowerCase()) {
				return true
			}
			return false
		}
		return true
	})

	console.log(rows);
	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={3}>
					<TextField
						select
						type="select"
						label="Combination"
						value={filter.combination}
						onChange={(e) =>
							setFilter({
								...filter,
								combination: e.target.value,
							})
						}
					>
						{combinations.map((combination) => (
							<MenuItem
								key={`${combination.toLowerCase()}`}
								value={combination}
							>
								{combination}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item xs={3}>
					<TextField
						type="text"
						label="Number"
						value={filter.number}
						onChange={(e) =>
							setFilter({
								...filter,
								number: e.target.value,
							})
						}
					/>
				</Grid>
				<Grid item xs={3}>
					<Button
						onClick={(e) => null}
						color="primary"
						variant="contained"
						className={classes.filterButton}
					>
						Filter
					</Button>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Table columns={columns} rows={rows} />
				</Grid>
			</Grid>
		</>
	);
};
